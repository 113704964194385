import React from "react";

function MoonSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fillRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit="2"
      clipRule="evenodd"
      viewBox="0 0 66 78"
    >
      <path
        fill="#D9D9D9"
        d="M150.838 394.99c20.837 0 37.754 16.918 37.754 37.755 0 20.838-16.917 37.755-37.754 37.755-20.838 0 13.236-16.038 13.236-36.876 0-20.837-34.074-38.634-13.236-38.634z"
        transform="translate(-6663.48 -5436.22) translate(0 5247.41) rotate(30 3864.536 12524.908) scale(1.06643)"
      ></path>
    </svg>
  );
}

export default MoonSvg;
