import React, { createContext, Dispatch, Reducer, TouchEventHandler, useReducer, useState } from "react";
import styles from './scroll-manager.module.css';

export interface ScrollState {
    delta: number;
    position: number;
    viewSize: number;
    viewHeight?: number;
}

/* ---------- Reducer needs -------------*/ 
const initialScrollState: ScrollState = {
    delta: 0, 
    position: 0, 
    viewSize: window.innerWidth,
    viewHeight: window.innerHeight
}

export enum GlobalScrollStateActionType {
    UPDATE = 'update',
    UPDATE_POSITION = 'update_position'
}

interface GlobalScrollStateAction {
    type: GlobalScrollStateActionType,
    scrollState: ScrollState
}

const scrollReducer = (globalScrollState: ScrollState, action: GlobalScrollStateAction) => {
    switch(action.type) {
        case GlobalScrollStateActionType.UPDATE : {
            return action.scrollState;
        }
        case GlobalScrollStateActionType.UPDATE_POSITION : {
            globalScrollState.position = action.scrollState.position;
            return globalScrollState;
        }
        default:
            return globalScrollState;
    }
}
/* ---------- End Reducer needs -------------*/

/* ---------- Context needs -------------*/
export const GlobalScrollStateContext = createContext<ScrollState | null>(null);
export const GlobalScrollDispatchContext = createContext<Dispatch<GlobalScrollStateAction> | null>(null);
/* ---------- End Context needs -------------*/

export default function ScrollManager (props: React.PropsWithChildren) {
    const [globalScrollState, globalScrollDispatch] = useReducer<Reducer<ScrollState, GlobalScrollStateAction>>(scrollReducer, initialScrollState);
    const [touchState, setTouchState] = useState<React.Touch>();

    const calculateNewScrollState = (delta: number, innerWidth: number, innerHeight: number) => {
        const scrollState: ScrollState = {
            delta: delta || 0,
            position: delta + globalScrollState.position,
            viewSize: innerWidth,
            viewHeight: innerHeight
        }

        if (scrollState.position >= 0) {
            scrollState.position = 0;
        }

        if (Math.abs(scrollState.position) >= scrollState.viewSize * 3) {
            scrollState.position = - (scrollState.viewSize * 3);
        }

        globalScrollDispatch({
            type: GlobalScrollStateActionType.UPDATE,
            scrollState
        });
    }

    const onScroll = (event: any) => {
        const scrollState: ScrollState = {
            delta: event.nativeEvent?.wheelDelta || 0,
            position: event.nativeEvent?.wheelDelta + globalScrollState.position,
            viewSize: event.view.innerWidth,
            viewHeight: event.view.innerHeight
        }

        if (scrollState.position >= 0) {
            scrollState.position = 0;
        }

        if (Math.abs(scrollState.position) >= scrollState.viewSize * 3) {
            scrollState.position = - (scrollState.viewSize * 3);
        }

        globalScrollDispatch({
            type: GlobalScrollStateActionType.UPDATE,
            scrollState
        });
        console.log("[SCROLL MANAGER]", {scrollState, event});
    }

    const onMoveStart: TouchEventHandler<HTMLElement> = (event) => {
        setTouchState(event.changedTouches[0]);
    }

    const onMoveEnd: TouchEventHandler<HTMLElement> = (event) => {
        setTouchState(undefined);
    }

    const onMove: TouchEventHandler<HTMLElement> = (event) => {
        if (event.changedTouches.length == 0) return;
        
        if (touchState) {
            const delta = (event.changedTouches[0]?.screenX - touchState.screenX) / 4;
            calculateNewScrollState(delta, window.innerWidth, window.innerHeight);
        }
    }

    return (
        <GlobalScrollStateContext.Provider value={globalScrollState}>
            <GlobalScrollDispatchContext.Provider value={globalScrollDispatch}>
                <div onWheel={onScroll} onTouchStart={onMoveStart} onTouchEnd={onMoveEnd} onTouchMove={onMove} className={styles.scrollWrapper}>
                    {props.children}
                </div>
            </GlobalScrollDispatchContext.Provider>
        </GlobalScrollStateContext.Provider>
    )
}