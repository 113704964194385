import React, { useContext, useEffect, useState } from "react";
import styles from './moon.module.css';
import { GlobalScrollStateContext, ScrollState } from "../../scroll-manager/scroll-manager";
import moonSvg from '../../../assets/svg/moon.svg';
import MoonSvg from "./moon-svg";

interface MoonProperties {
    x: number;
    y: number;
}

export default function Moon () {

    const [moonPosition, setMoonPosition] = useState<MoonProperties>({x: 45, y: -330});
    const [moonSize, setMoonSize] = useState<number>(103);
    const globalScrollState = useContext(GlobalScrollStateContext);

    // Calculate parabolic line chatgbt answer 
    const calculateY = (x: number, scrollState: ScrollState):number => {
        const xa = 45;
        const ya = -50;
        const xb = scrollState.viewSize / 2;
        const yb = (scrollState.viewHeight || 920) - 190;
        const xc = scrollState.viewSize - 90;
        const yc = -50;
        console.log('xb: ', xb);

        const a = ((yb - yc) * (xa - xb) - (ya - yb) * (xb - xc)) /
          ((xa - xb) * (xb - xc) * (xc - xa));
        const b = ((ya - yb) / (xa - xb)) - a * (xa + xb);
        const c = ya - a * xa ** 2 - b * xa;

        return a * Math.pow(x, 2) + b*x + c;
    }

    // const quadraticCalculation = (x: number, a: number, b: number, c: number):number => {
    //     console.log(Math.pow((a*x), 2) + (b*x) + c);
    //     return a* Math.pow(x, 2) + (b*x) + c;
    // }

    
    useEffect(() => {
        const changePosition = (scrollState: ScrollState) => {
            if (scrollState.position !== 0) {
                let newX = (Math.abs(scrollState.position) - (scrollState.viewSize * 2)) / 2;
                console.log('[MOON] newX: ',newX);
                const pos = {
                    x: newX,
                    // y: quadraticCalculation(newX, -167/278400, 167/145, 1037085/3712),
                    y: calculateY(newX, scrollState),
                } ;
                setMoonPosition(pos);
                return pos;
            }
    
            return moonPosition;
        }
    
        // Calculate size from y position - chatgbt answer
        const changeSize = (y: number,scrollState: ScrollState) => {
            const ya = 330;
            const sa = 65;
            const yb = (scrollState.viewHeight || 920) - 98;
            const sb = 50;
    
            const fpy = (sb - sa)/(yb - ya);
            const b = sa - fpy * ya;
    
            setMoonSize(fpy * y + b);
        }

        if (!globalScrollState) return;
        
        const pos = changePosition(globalScrollState);

        if (pos.y) {
            changeSize(pos.y, globalScrollState);
        }
    }, [globalScrollState]);

    return (
        <div className={styles.moon} style={{
            bottom: `${moonPosition.y}px`,
            left: `${moonPosition.x}px`,
            width: `${moonSize}px`,
            height: `${moonSize}px`,
        }} >
            <MoonSvg />
        </div>
    )
}