import React from "react";

function HouseSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fillRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit="2"
      clipRule="evenodd"
      viewBox="0 0 155 130"
    >
      <g transform="translate(-6577.55 -5969.08) translate(0 5247.41) translate(-27.06)">
        <g>
          <path
            fill="#FFFAD3"
            d="M3573.06 687.479l.59 78.265-59.42-30.651v-51.759l24.75-35.926 25.7 39.777 8.38.294z"
            transform="matrix(.71271 0 0 .7735 4148.04 232.214)"
          ></path>
          <path
            fill="#333"
            d="M3528.8 742.609v-28.061c5.05-9.98 10.09-7.739 15.14 5.758v29.938l-15.14-7.635z"
            transform="matrix(.71271 0 0 .7735 4148.04 232.214)"
          ></path>
          <path
            fill="#FFFAE0"
            d="M3651.85 684.369l-78.79 3.11.59 78.265 79.02-2.263-.82-79.112z"
            transform="matrix(.71271 0 0 .7735 4148.04 232.214) matrix(1.00393 0 0 1 -14.345 0)"
          ></path>
          <path
            fill="#333"
            d="M3585.83 734.775l-.57 15.469h21.84l-.34-15.776-20.93.307z"
            transform="matrix(.71271 0 0 .7735 4148.04 232.214) translate(.48 -5.162)"
          ></path>
          <path
            fill="#333"
            d="M3585.83 734.775l-.57 15.469h21.84l-.34-15.776-20.93.307z"
            transform="matrix(.71271 0 0 .7735 4148.04 232.214) translate(36.943 -17.3)"
          ></path>
          <path
            fill="#F7E018"
            d="M3664.01 684.369l-90.95 3.11-35.8-53.591 89.54-1.105 37.21 51.586z"
            transform="matrix(.71271 0 0 .7735 4148.04 232.214)"
          ></path>
          <path
            fill="url(#_Linear1)"
            d="M3573.06 687.479h-7.94l-26.42-40.071-24.47 35.882-11.9 1.079 34.93-50.129 35.8 53.239z"
            transform="matrix(.71271 0 0 .7735 4148.04 232.214)"
          ></path>
        </g>
        <g>
          <path
            fill="#333"
            d="M6552.72 6012.2H6554.952V6048.465999999999H6552.72z"
            transform="matrix(.7026 0 0 .7026 2022.19 290.5) translate(0 -5250.41)"
          ></path>
          <path
            fill="#444"
            d="M230.435 557.312l-.791-204.743C218.563 250.681 86.839 193.851 88.142 271.146l-7.114 200.791 149.407 85.375z"
            transform="matrix(.7026 0 0 .7026 2022.19 290.5) translate(6513.03 709.392) scale(.11084)"
          ></path>
          <path
            fill="#333"
            d="M230.435 557.312l358.893-149.407-.79-185.771c-6.853-77.861-64.794-126.909-150.198-101.185-104.558 23.826-207.695 60.937-324.901 114.276 76.453 8.32 119.137 77.331 116.996 133.945v188.142z"
            transform="matrix(.7026 0 0 .7026 2022.19 290.5) translate(6513.03 709.392) scale(.11084)"
          ></path>
          <path
            fill="#AE0000"
            d="M300 418.182V300l98.024-35.968 1.581-84.585-131.226 37.154-.79 200c7.199 22.152 16.776 30.044 32.411 1.581z"
            transform="matrix(.7026 0 0 .7026 2022.19 290.5) translate(6513.03 709.392) scale(.11084)"
          ></path>
        </g>
      </g>
      <defs>
        <linearGradient
          id="_Linear1"
          x1="0"
          x2="1"
          y1="0"
          y2="0"
          gradientTransform="matrix(70.7283 0 0 53.2394 3502.33 660.859)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#6B4C35"></stop>
          <stop offset="0.5" stopColor="#5A3D2B"></stop>
          <stop offset="1" stopColor="#543828"></stop>
        </linearGradient>
      </defs>
    </svg>
  );
}

export default HouseSvg;
