import React from "react";
import styles from './abstract-section.module.css';

export default function AbstractSection (props: React.PropsWithChildren) {

    return (
        <section className={styles.abstractSection}>
            {props.children}
        </section>
    )
}