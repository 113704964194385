import { useContext, useState } from "react";
import { GlobalScrollDispatchContext, GlobalScrollStateActionType, GlobalScrollStateContext } from "../../scroll-manager/scroll-manager";
import AbstractSection from "../abstract-section/abstract-section";
import BackEnd from "./back-end/back-end";
import FrontEnd from "./front-end/front-end";

export enum AfternoonContentType {
    FRONT_END = 1,
    BACK_END = 2
}

export default function AfternoonContent() {
    const [selectedContent, setSelectedContent] = useState<AfternoonContentType>(AfternoonContentType.FRONT_END);
    
    const globalScrollState = useContext(GlobalScrollStateContext);
    const scrollStateDispatch = useContext(GlobalScrollDispatchContext);

    const selectContent = (type: AfternoonContentType) => {
        setSelectedContent(type);
        if (!globalScrollState || !scrollStateDispatch) return;

        scrollStateDispatch({
            type: GlobalScrollStateActionType.UPDATE,
            scrollState: {
                delta: globalScrollState.delta,
                position: -globalScrollState.viewSize,
                viewSize: globalScrollState.viewSize,
                viewHeight: globalScrollState.viewHeight
            }
        });
    };

    return (
        <AbstractSection>
            <FrontEnd 
                onSelect={() => selectContent(AfternoonContentType.FRONT_END)} 
                active={selectedContent === AfternoonContentType.FRONT_END}
            />
            <BackEnd 
                onSelect={() => selectContent(AfternoonContentType.BACK_END)} 
                active={selectedContent === AfternoonContentType.BACK_END}
            />
        </AbstractSection>
    )
}