import AbstractSection from "../abstract-section/abstract-section";
import styles from './morning-content.module.css';

export default function MorningContent() {

    return (
        <AbstractSection>
            <div className={styles.wrapper}>
                <h1 className={styles.h1}>Quentin Pavy</h1>
                <h3 className={styles.h3}>Expert developer front-end & back-end</h3>
            </div>
        </AbstractSection>
    )
}