import SkillsList, { WorkSkill } from '../skills-list/skills-list';
import styles from './work-description.module.css';

interface WorkDescriptionProps {
    description: string;
    realisation: string;
    skills: Array<WorkSkill>;
}

export default function WorkDescription (props: WorkDescriptionProps) {
    return (
        <div className={styles.workDescriptionWrapper}>
            <SkillsList skills={props.skills} expandable={true} />
            <div className={styles.workRealisation}>{props.realisation}</div>
            <div className={styles.workDescription} dangerouslySetInnerHTML={{__html: props.description}} />
        </div>
    );
}