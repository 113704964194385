import styles from './layer-one.module.css';
import React, { useEffect, useState } from "react";

export enum LayerOneSvgGradient {
  MORNING = 0,
  AFTERNOON = 1,
  EVENING = 2,
  NIGHT = 3
}

interface LayerOneTreesSvgProps {
    gradient: LayerOneSvgGradient
}

function LayerOneSvg(props: LayerOneTreesSvgProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fillRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit="2"
      clipRule="evenodd"
      viewBox="0 0 7702 386" // 449 * 0.86 car la base = 1080 et le viewport = 929 929/1080 = 0.86
      // viewBox="0 0 7702 449"
    >
      <g transform="translate(3)">
        <g>
          <path
            fill="#333"
            fillRule="nonzero"
            d="M237.5 39L210 172h50.5l-23-133z"
          ></path>
          <path
            fill="#333"
            fillRule="nonzero"
            d="M237.5 39L210 172h50.5l-23-133z"
            transform="translate(1777.38 172.5)"
          ></path>
          <path
            fill="#333"
            fillRule="nonzero"
            d="M237.5 39L210 172h50.5l-23-133z"
            transform="translate(2005.08 172.5)"
          ></path>
          <path
            fill="#333"
            fillRule="nonzero"
            d="M237.5 39L210 172h50.5l-23-133z"
            transform="translate(2072.55 195)"
          ></path>
          <path
            fill="#333"
            fillRule="nonzero"
            d="M237.5 39L210 172h50.5l-23-133z"
            transform="translate(2123.05 210.5)"
          ></path>
          <path
            fill="#333"
            fillRule="nonzero"
            d="M237.5 39L210 172h50.5l-23-133z"
            transform="translate(2574.25 106)"
          ></path>
          <path
            fill="#333"
            fillRule="nonzero"
            d="M237.5 39L210 172h50.5l-23-133z"
            transform="translate(2825.15 77.5)"
          ></path>
          <path
            fill="#333"
            fillRule="nonzero"
            d="M237.5 39L210 172h50.5l-23-133z"
            transform="translate(3177.25 106)"
          ></path>
          <path
            fill="#333"
            fillRule="nonzero"
            d="M237.5 39L210 172h50.5l-23-133z"
            transform="translate(3575.36 159.539)"
          ></path>
          <path
            fill="#333"
            fillRule="nonzero"
            d="M237.5 39L210 172h50.5l-23-133z"
            transform="translate(3630 159.539)"
          ></path>
          <path
            fill="#333"
            fillRule="nonzero"
            d="M237.5 39L210 172h50.5l-23-133z"
            transform="translate(3733.31 159.539)"
          ></path>
          <path
            fill="#333"
            fillRule="nonzero"
            d="M237.5 39L210 172h50.5l-23-133z"
            transform="translate(4176.07 143.471)"
          ></path>
          <path
            fill="#333"
            fillRule="nonzero"
            d="M237.5 39L210 172h50.5l-23-133z"
            transform="translate(4458.6 106.062)"
          ></path>
          <path
            fill="#333"
            fillRule="nonzero"
            d="M237.5 39L210 172h50.5l-23-133z"
            transform="translate(5061.6 143.471)"
          ></path>
          <path
            fill="#333"
            fillRule="nonzero"
            d="M237.5 39L210 172h50.5l-23-133z"
            transform="translate(5190.21 175.608)"
          ></path>
          <path
            fill="#333"
            fillRule="nonzero"
            d="M237.5 39L210 172h50.5l-23-133z"
            transform="translate(5498.04 109.108)"
          ></path>
          <path
            fill="#333"
            fillRule="nonzero"
            d="M237.5 39L210 172h50.5l-23-133z"
            transform="translate(6033.57 143.471)"
          ></path>
          <path
            fill="#333"
            fillRule="nonzero"
            d="M237.5 39L210 172h50.5l-23-133z"
            transform="translate(6328.75 143.471)"
          ></path>
          <path
            fill="#333"
            fillRule="nonzero"
            d="M237.5 39L210 172h50.5l-23-133z"
            transform="translate(6666.09 106.062)"
          ></path>
          <path
            fill="#333"
            fillRule="nonzero"
            d="M237.5 39L210 172h50.5l-23-133z"
            transform="translate(6935.96 106.062)"
          ></path>
          <path
            fill="#333"
            fillRule="nonzero"
            d="M237.5 39L210 172h50.5l-23-133z"
            transform="translate(7395.59 176.401)"
          ></path>
          <path
            fill="#333"
            fillRule="nonzero"
            d="M500.5 0L473 133h50.5l-23-133z"
          ></path>
          <path
            fill="#333"
            fillRule="nonzero"
            d="M550.5 0L523 133h50.5l-23-133zM647.5 21L620 154h50.5l-23-133zM878.5 66L851 199h50.5l-23-133zM1200.5 146L1173 279h50.5l-23-133zM1569.5 159L1542 292h50.5l-23-133zM1812.5 172L1785 305h50.5l-23-133zM280.5 106.5l-18 52h32l-14-52zM172 146l-18 52h32l-14-52z"
          ></path>
          <path
            fill="#333"
            fillRule="nonzero"
            d="M172 146l-18 52h32l-14-52z"
            transform="translate(1806.89 119.411)"
          ></path>
          <path
            fill="#333"
            fillRule="nonzero"
            d="M172 146l-18 52h32l-14-52z"
            transform="translate(2104.18 142.604)"
          ></path>
          <path
            fill="#333"
            fillRule="nonzero"
            d="M172 146l-18 52h32l-14-52z"
            transform="translate(2591.22 73.027)"
          ></path>
          <path
            fill="#333"
            fillRule="nonzero"
            d="M172 146l-18 52h32l-14-52z"
            transform="translate(2549.05 85.677)"
          ></path>
          <path
            fill="#333"
            fillRule="nonzero"
            d="M172 146l-18 52h32l-14-52z"
            transform="translate(2947.54 39.292)"
          ></path>
          <path
            fill="#333"
            fillRule="nonzero"
            d="M172 146l-18 52h32l-14-52z"
            transform="translate(3293.31 54.051)"
          ></path>
          <path
            fill="#333"
            fillRule="nonzero"
            d="M172 146l-18 52h32l-14-52z"
            transform="translate(3746.62 121.52)"
          ></path>
          <path
            fill="#333"
            fillRule="nonzero"
            d="M172 146l-18 52h32l-14-52z"
            transform="translate(3590.6 104.652)"
          ></path>
          <path
            fill="#333"
            fillRule="nonzero"
            d="M172 146l-18 52h32l-14-52z"
            transform="translate(4292.69 96.219)"
          ></path>
          <path
            fill="#333"
            fillRule="nonzero"
            d="M172 146l-18 52h32l-14-52z"
            transform="translate(4478.23 66.701)"
          ></path>
          <path
            fill="#333"
            fillRule="nonzero"
            d="M172 146l-18 52h32l-14-52z"
            transform="translate(4577.33 47.617)"
          ></path>
          <path
            fill="#333"
            fillRule="nonzero"
            d="M172 146l-18 52h32l-14-52z"
            transform="translate(4577.33 47.617)"
          ></path>
          <path
            fill="#333"
            fillRule="nonzero"
            d="M172 146l-18 52h32l-14-52z"
            transform="translate(5157.13 117.303)"
          ></path>
          <path
            fill="#333"
            fillRule="nonzero"
            d="M172 146l-18 52h32l-14-52z"
            transform="translate(5513.45 96.219)"
          ></path>
          <path
            fill="#333"
            fillRule="nonzero"
            d="M172 146l-18 52h32l-14-52z"
            transform="translate(6055.31 100.436)"
          ></path>
          <path
            fill="#333"
            fillRule="nonzero"
            d="M172 146l-18 52h32l-14-52z"
            transform="translate(6148.08 117.303)"
          ></path>
          <path
            fill="#333"
            fillRule="nonzero"
            d="M172 146l-18 52h32l-14-52z"
            transform="translate(6352.59 110.978)"
          ></path>
          <path
            fill="#333"
            fillRule="nonzero"
            d="M172 146l-18 52h32l-14-52z"
            transform="translate(6447.47 98.327)"
          ></path>
          <path
            fill="#333"
            fillRule="nonzero"
            d="M172 146l-18 52h32l-14-52z"
            transform="translate(6485.42 106.761)"
          ></path>
          <path
            fill="#333"
            fillRule="nonzero"
            d="M172 146l-18 52h32l-14-52z"
            transform="translate(6685.72 81.46)"
          ></path>
          <path
            fill="#333"
            fillRule="nonzero"
            d="M172 146l-18 52h32l-14-52z"
            transform="translate(7046.26 56.16)"
          ></path>
          <path
            fill="#333"
            fillRule="nonzero"
            d="M172 146l-18 52h32l-14-52z"
            transform="translate(7092.64 75.135)"
          ></path>
          <path
            fill="#333"
            fillRule="nonzero"
            d="M172 146l-18 52h32l-14-52z"
            transform="translate(7276.07 115.194)"
          ></path>
          <path
            fill="#333"
            fillRule="nonzero"
            d="M172 146l-18 52h32l-14-52z"
            transform="translate(7406.79 142.604)"
          ></path>
          <path
            fill="#333"
            fillRule="nonzero"
            d="M172 146l-18 52h32l-14-52z"
            transform="translate(5304.72 151.037)"
          ></path>
          <path
            fill="#333"
            fillRule="nonzero"
            d="M591 80l-18 52h32l-14-52zM837 115l-18 52h32l-14-52zM1147 200l-18 52h32l-14-52zM1307 234l-18 52h32l-14-52zM1351 234l-18 52h32l-14-52zM1517 234l-18 52h32l-14-52z"
          ></path>
          <path
            fill="#333"
            fillRule="nonzero"
            d="M1866 240l-18 52h32l-14-52z"
            transform="translate(0 10)"
          ></path>
          <path
            fill="#333"
            fillRule="nonzero"
            d="M1692 252l-18 52h32l-14-52z"
          ></path>
          <path
            fill="#333"
            fillRule="nonzero"
            d="M1918.65 449S6739 464.302 7699 449c30.6-.488 27.2-77.441 8.71-95.489-18.49-18.048-102.03-12.362-138.67-19.798-92.07-18.681-303.63-86.833-441.45-95.618-129.01-8.224-256.52 33.29-385.44 42.909-140.01 10.445-327.24 26.917-454.62 19.766-105.15-5.904-204.35-62.675-309.67-62.675-111.06 0-280.2 47.287-356.67 62.675-34.75 6.993-67.01 24.773-102.12 29.649-39.53 5.491-97.18 12.628-135.07 3.294-35.49-8.744-58.35-45.605-92.24-59.298-54.36-21.963-152.48-66.722-233.9-72.476-85.66-6.054-187.23 22.429-280.02 36.156-92.52 13.685-183.91 34.774-276.73 46.203-102.67 12.642-229.13 25.806-339.32 29.649-107.22 3.74-215.01 3.786-321.79-6.589-130.13-12.642-304.67-57.932-458.97-69.263-157.21-11.544-323.63-15.815-484.28 0-167.46 16.485-357.72 88.401-520.51 98.913-152.23 9.83-456.24-35.842-456.24-35.842s-227.8-34.709-380.07-19.946C1156.8 318.365 866.31 115 573.434 115S-3 250.394-3 250.394V449h1921.65z"
          ></path>
          <path
            className={`${styles.gradient}`}
            style={{opacity: props.gradient == LayerOneSvgGradient.MORNING ? 1 : 0}}
            fill="url(#morning-gradient)"
            fillRule="nonzero"
            d="M1918.65 449S6739 464.302 7699 449c30.6-.488 27.2-77.441 8.71-95.489-18.49-18.048-102.03-12.362-138.67-19.798-92.07-18.681-303.63-86.833-441.45-95.618-129.01-8.224-256.52 33.29-385.44 42.909-140.01 10.445-327.24 26.917-454.62 19.766-105.15-5.904-204.35-62.675-309.67-62.675-111.06 0-280.2 47.287-356.67 62.675-34.75 6.993-67.01 24.773-102.12 29.649-39.53 5.491-97.18 12.628-135.07 3.294-35.49-8.744-58.35-45.605-92.24-59.298-54.36-21.963-152.48-66.722-233.9-72.476-85.66-6.054-187.23 22.429-280.02 36.156-92.52 13.685-183.91 34.774-276.73 46.203-102.67 12.642-229.13 25.806-339.32 29.649-107.22 3.74-215.01 3.786-321.79-6.589-130.13-12.642-304.67-57.932-458.97-69.263-157.21-11.544-323.63-15.815-484.28 0-167.46 16.485-357.72 88.401-520.51 98.913-152.23 9.83-456.24-35.842-456.24-35.842s-227.8-34.709-380.07-19.946C1156.8 318.365 866.31 115 573.434 115S-3 250.394-3 250.394V449h1921.65z"
          ></path>
          <path
            className={`${styles.gradient}`}
            style={{opacity: props.gradient == LayerOneSvgGradient.AFTERNOON ? 1 : 0}}
            fill="url(#afternoon-gradient)"
            fillRule="nonzero"
            d="M1918.65 449S6739 464.302 7699 449c30.6-.488 27.2-77.441 8.71-95.489-18.49-18.048-102.03-12.362-138.67-19.798-92.07-18.681-303.63-86.833-441.45-95.618-129.01-8.224-256.52 33.29-385.44 42.909-140.01 10.445-327.24 26.917-454.62 19.766-105.15-5.904-204.35-62.675-309.67-62.675-111.06 0-280.2 47.287-356.67 62.675-34.75 6.993-67.01 24.773-102.12 29.649-39.53 5.491-97.18 12.628-135.07 3.294-35.49-8.744-58.35-45.605-92.24-59.298-54.36-21.963-152.48-66.722-233.9-72.476-85.66-6.054-187.23 22.429-280.02 36.156-92.52 13.685-183.91 34.774-276.73 46.203-102.67 12.642-229.13 25.806-339.32 29.649-107.22 3.74-215.01 3.786-321.79-6.589-130.13-12.642-304.67-57.932-458.97-69.263-157.21-11.544-323.63-15.815-484.28 0-167.46 16.485-357.72 88.401-520.51 98.913-152.23 9.83-456.24-35.842-456.24-35.842s-227.8-34.709-380.07-19.946C1156.8 318.365 866.31 115 573.434 115S-3 250.394-3 250.394V449h1921.65z"
          ></path>
          <path
            className={`${styles.gradient}`}
            style={{opacity: props.gradient == LayerOneSvgGradient.EVENING ? 1 : 0}}
            fill="url(#evening-gradient)"
            fillRule="nonzero"
            d="M1918.65 449S6739 464.302 7699 449c30.6-.488 27.2-77.441 8.71-95.489-18.49-18.048-102.03-12.362-138.67-19.798-92.07-18.681-303.63-86.833-441.45-95.618-129.01-8.224-256.52 33.29-385.44 42.909-140.01 10.445-327.24 26.917-454.62 19.766-105.15-5.904-204.35-62.675-309.67-62.675-111.06 0-280.2 47.287-356.67 62.675-34.75 6.993-67.01 24.773-102.12 29.649-39.53 5.491-97.18 12.628-135.07 3.294-35.49-8.744-58.35-45.605-92.24-59.298-54.36-21.963-152.48-66.722-233.9-72.476-85.66-6.054-187.23 22.429-280.02 36.156-92.52 13.685-183.91 34.774-276.73 46.203-102.67 12.642-229.13 25.806-339.32 29.649-107.22 3.74-215.01 3.786-321.79-6.589-130.13-12.642-304.67-57.932-458.97-69.263-157.21-11.544-323.63-15.815-484.28 0-167.46 16.485-357.72 88.401-520.51 98.913-152.23 9.83-456.24-35.842-456.24-35.842s-227.8-34.709-380.07-19.946C1156.8 318.365 866.31 115 573.434 115S-3 250.394-3 250.394V449h1921.65z"
          ></path>
          <path
            className={`${styles.gradient}`}
            style={{opacity: props.gradient == LayerOneSvgGradient.NIGHT ? 1 : 0}}
            fill="url(#night-gradient)"
            fillRule="nonzero"
            d="M1918.65 449S6739 464.302 7699 449c30.6-.488 27.2-77.441 8.71-95.489-18.49-18.048-102.03-12.362-138.67-19.798-92.07-18.681-303.63-86.833-441.45-95.618-129.01-8.224-256.52 33.29-385.44 42.909-140.01 10.445-327.24 26.917-454.62 19.766-105.15-5.904-204.35-62.675-309.67-62.675-111.06 0-280.2 47.287-356.67 62.675-34.75 6.993-67.01 24.773-102.12 29.649-39.53 5.491-97.18 12.628-135.07 3.294-35.49-8.744-58.35-45.605-92.24-59.298-54.36-21.963-152.48-66.722-233.9-72.476-85.66-6.054-187.23 22.429-280.02 36.156-92.52 13.685-183.91 34.774-276.73 46.203-102.67 12.642-229.13 25.806-339.32 29.649-107.22 3.74-215.01 3.786-321.79-6.589-130.13-12.642-304.67-57.932-458.97-69.263-157.21-11.544-323.63-15.815-484.28 0-167.46 16.485-357.72 88.401-520.51 98.913-152.23 9.83-456.24-35.842-456.24-35.842s-227.8-34.709-380.07-19.946C1156.8 318.365 866.31 115 573.434 115S-3 250.394-3 250.394V449h1921.65z"
          ></path>
        </g>
      </g>
      <defs>
        <radialGradient
          id="morning-gradient"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="matrix(1482.41 213.354 -331.077 2300.36 437.592 228.646)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor={`var(--layer-1-morning-start)`}></stop>
          <stop offset="1" stopColor={`var(--layer-1-morning-end)`}></stop>
        </radialGradient>
        <radialGradient
          id="afternoon-gradient"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="matrix(1402.46 53.4655 -82.9663 2176.3 2973.28 324.231)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor={`var(--layer-1-afternoon-start)`}></stop>
          <stop offset="1" stopColor={`var(--layer-1-afternoon-end)`}></stop>
        </radialGradient>
        <radialGradient
          id="evening-gradient"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="matrix(1482.41 213.354 -331.077 2300.36 5672.72 367.588)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor={`var(--layer-1-evening-start)`}></stop>
          <stop offset="1" stopColor={`var(--layer-1-evening-end)`}></stop>
        </radialGradient>
        <radialGradient
          id="night-gradient"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="matrix(476.864 29.7263 -46.1284 739.986 6694.94 383.549)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor={`var(--layer-1-night-start)`}></stop>
          <stop offset="1" stopColor={`var(--layer-1-night-end)`}></stop>
        </radialGradient>
      </defs>
    </svg>
  );
}

export default LayerOneSvg;

