import React, { useContext, useEffect, useState } from "react";
import { GlobalScrollStateContext, ScrollState } from "../../scroll-manager/scroll-manager";
import LayerTwoSvg, { LayerTwoSvgGradient } from "./layer-two-svg";
import styles from './layer-two.module.css';

export default function LayerTwo () {
    const [position, setPosition] = useState<number>(0);
    const [gradient, setGradient] = useState<LayerTwoSvgGradient>(LayerTwoSvgGradient.MORNING);
    const globalScrollState = useContext(GlobalScrollStateContext);

    const changeGradient = (scrollState: ScrollState) => {
        const gradientIndex = Math.floor((Math.abs(scrollState.position) + Math.abs(scrollState.delta * 4))/scrollState.viewSize);
        setGradient(gradientIndex);
    }

    useEffect(() => {
        if (!globalScrollState) return;
        setPosition(globalScrollState.position / 2);
        changeGradient(globalScrollState);
    }, [globalScrollState]);
    return (
        <div className={styles.layerTwo} style={{
            left: `${position}px`
        }}>
            <LayerTwoSvg gradient={gradient} />
        </div>
    )
}