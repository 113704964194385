import React, { MouseEventHandler } from "react";
import AbstractContent from "../../abstract-content/abstract-content";
import WindMillSvg from "../elements/windmill/windmill-svg";
import styles from './back-end.module.css';

interface BackEndProps {
    onSelect: MouseEventHandler,
    active: boolean
}

export default function BackEnd (props:BackEndProps) {

    return (
        <>
            <AbstractContent active={props.active}>
                <h2>Back-end</h2>
                <p>As a Javascript and Typescript expert, i mainly develop back-ends with Node.js. I like creating clear and scallable API's that will last for a long time. I also use CMS like Strapi, Wordpress and Drupal to create custom and easy to use back-ends for users.</p>
            </AbstractContent>
            <div className={styles.windmills} data-active={props.active} onClick={props.onSelect}>
                <div className={styles.cta}><div></div></div>
                <div className={`${styles.windmillWrapper} ${styles.left}`}>
                    <WindMillSvg />
                </div>
                <div className={`${styles.windmillWrapper} ${styles.center}`}>
                    <WindMillSvg />
                </div>
                <div className={`${styles.windmillWrapper} ${styles.right}`}>
                    <WindMillSvg />
                </div>
            </div>
        </>
    );
}