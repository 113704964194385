import AbstractSection from "../abstract-section/abstract-section";
import Contact from "./contact/contact";
import styles from './night-content.module.css';

export default function NightContent() {

    return (
        <AbstractSection>
            <div className={styles.wrapper}>
                <h2 className={styles.h2}>Contact me</h2>
                <p className={styles.p}>Want to work with me ? Please feel free to contact me for any question or if you just want to say "Hello".</p>
            </div>
            <Contact />
        </AbstractSection>
    )
}