import React from "react";
import styles from './windmill-svg.module.css';

function WindMillSvg() {
    return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          fillRule="evenodd"
          strokeLinejoin="round"
          strokeMiterlimit="2"
          clipRule="evenodd"
          viewBox="0 0 346 366"
        >
          <g transform="translate(-3309.41 -1878.7) translate(-2198.42 826.213) scale(1.58733)">
            <g>
              <path
                fill="#333"
                d="M3518 2206l17-23h29l5-217h20l3 217h29l17 22-120 1z"
                transform="matrix(.43322 0 0 .4524 2026.53 506.711) translate(4.617 -1351)"
              ></path>
              </g>
            <g className={styles.rotate}>
            <path
                fill="#77B063"
                d="M3557 1961c-7.97 7.83-12.81 17.9-12 32l-187 56 181-100h12l6 12z"
                transform="matrix(.43322 0 0 .4524 2026.53 506.711) matrix(1.00001 0 .00123 1 731.157 82.72) matrix(1 0 0 1 -700.608 -63.157) translate(-26.264 -1367.54)"
            ></path>
            <path
                fill="#333"
                d="M3557 1961c-7.97 7.83-12.81 17.9-12 32l-187 56 181-100h12l6 12zm-8.64-1.16c-6.27 7.42-10.27 16.47-10.68 28.06l-119.1 35.67 122.36-67.61h5.48l1.94 3.88z"
                transform="matrix(.43322 0 0 .4524 2026.53 506.711) matrix(1.00001 0 .00123 1 731.157 82.72) matrix(1 0 0 1 -700.608 -63.157) translate(-26.264 -1367.54)"
            ></path>
            <path
                fill="#77B063"
                d="M3557 1961c-7.97 7.83-12.81 17.9-12 32l-187 56 181-100h12l6 12z"
                transform="matrix(.43322 0 0 .4524 2026.53 506.711) matrix(1.00001 0 .00123 1 731.157 82.72) matrix(1 0 0 1 -700.608 -63.157) rotate(120 3962.538 1249.378)"
            ></path>
            <path
                fill="#333"
                d="M3557 1961c-7.97 7.83-12.81 17.9-12 32l-187 56 181-100h12l6 12zm-8.52-1.46c-6.22 7.41-10.16 16.46-10.58 28.01l-117.15 35.09 120.27-66.45h5.79l1.67 3.35z"
                transform="matrix(.43322 0 0 .4524 2026.53 506.711) matrix(1.00001 0 .00123 1 731.157 82.72) matrix(1 0 0 1 -700.608 -63.157) rotate(120 3962.538 1249.378)"
            ></path>
            <g>
                <path
                fill="#77B063"
                d="M3557 1961c-7.97 7.83-12.81 17.9-12 32l-187 56 181-100h12l6 12z"
                transform="matrix(.43322 0 0 .4524 2026.53 506.711) matrix(1.00001 0 .00123 1 731.157 82.72) matrix(1 0 0 1 -700.608 -63.157) rotate(-120 3169.76 1264.766)"
                ></path>
                <path
                fill="#333"
                d="M3557 1961c-7.97 7.83-12.81 17.9-12 32l-187 56 181-100h12l6 12zm-8.54-1.03c-6.12 7.38-10.04 16.33-10.53 27.72l-118.75 35.56 121.37-67.05h6.03l1.88 3.77z"
                transform="matrix(.43322 0 0 .4524 2026.53 506.711) matrix(1.00001 0 .00123 1 731.157 82.72) matrix(1 0 0 1 -700.608 -63.157) rotate(-120 3169.76 1264.766)"
                ></path>
              <g>
                <g transform="matrix(.43322 0 0 .4524 2026.53 506.711) matrix(1.00001 0 .00123 1 731.157 82.72) matrix(.45748 0 0 .45748 2751.3 434.208) translate(-104.408 -218.688)">
                  <clipPath id="_clip1">
                    <path d="M318.707 302.139l-56.173 32.423a6.78 6.78 0 00-3.395 5.875v64.89a6.778 6.778 0 003.395 5.875l56.177 32.448a6.802 6.802 0 006.786 0l56.168-32.448a6.793 6.793 0 003.387-5.875v-64.89a6.778 6.778 0 00-3.4-5.875l-56.16-32.423a6.83 6.83 0 00-6.8 0"></path>
                  </clipPath>
                  <g clipPath="url(#_clip1)">
                    <clipPath id="_clip2">
                      <path
                        d="M441.817 329.057L283.531 251.47l-81.16 165.565 158.282 77.591 81.164-165.569z"
                        clipRule="nonzero"
                      ></path>
                    </clipPath>
                    <g clipPath="url(#_clip2)">
                      <use
                        width="39.637"
                        height="47.085"
                        x="154.18"
                        y="153.081"
                        transform="matrix(3.17871 0 -.00375 3.04393 -225.89 -155.853) scale(.99091 .98094)"
                        xlinkHref="#_Image3"
                      ></use>
                    </g>
                  </g>
                </g>
                <g transform="matrix(.43322 0 0 .4524 2026.53 506.711) matrix(1.00001 0 .00123 1 731.157 82.72) matrix(.45748 0 0 .45748 2751.3 434.208) translate(-104.408 -218.688)">
                  <clipPath id="_clip4">
                    <path d="M260.531 409.447a6.776 6.776 0 002 1.755l48.186 27.833 8.027 4.614a6.81 6.81 0 003.912.886 6.93 6.93 0 001.333-.244l59.246-108.48a6.742 6.742 0 00-1.579-1.253l-36.781-21.24-19.443-11.187a7.094 7.094 0 00-1.76-.706l-63.141 108.022z"></path>
                  </clipPath>
                  <g clipPath="url(#_clip4)">
                    <clipPath id="_clip5">
                      <path
                        d="M192.094 352.005l111.766 151.27 147.813-109.208L339.9 242.801 192.094 352.005z"
                        clipRule="nonzero"
                      ></path>
                    </clipPath>
                    <g clipPath="url(#_clip5)">
                      <use
                        width="38.573"
                        height="47.021"
                        x="154.939"
                        y="153.353"
                        transform="matrix(3.17871 0 -.00375 3.04393 -225.89 -155.853) scale(.98906 .9796)"
                        xlinkHref="#_Image6"
                      ></use>
                    </g>
                  </g>
                </g>
                <g transform="matrix(.43322 0 0 .4524 2026.53 506.711) matrix(1.00001 0 .00123 1 731.157 82.72) matrix(.45748 0 0 .45748 2751.3 434.208) translate(-104.408 -218.688)">
                  <clipPath id="_clip7">
                    <path d="M321.421 301.27a6.857 6.857 0 00-2.713.869l-56.013 32.33 60.4 110.013c.84-.12 1.666-.4 2.413-.832l56.173-32.448a6.805 6.805 0 003.28-4.635l-61.573-105.186a7.002 7.002 0 00-1.373-.136c-.187 0-.374.009-.56.026"></path>
                  </clipPath>
                  <g clipPath="url(#_clip7)">
                    <g transform="translate(-.286 .443)">
                      <clipPath id="_clip8">
                        <path
                          d="M262.694 301.245H384.938V444.485H262.694z"
                          clipRule="nonzero"
                        ></path>
                      </clipPath>
                      <g clipPath="url(#_clip8)">
                        <use
                          width="38.41"
                          height="46.911"
                          x="156.261"
                          y="150.597"
                          transform="matrix(3.17871 0 -.00375 3.04393 -225.604 -156.296) scale(.98486 .9981)"
                          xlinkHref="#_Image9"
                        ></use>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
              <g transform="matrix(.43322 0 0 .4524 2026.53 506.711) matrix(1.00001 0 .00123 1 731.157 82.72) matrix(.75553 1.93052 -2.0169 .72577 4571.08 -6713.64)">
                <circle
                  cx="2996.47"
                  cy="1975.41"
                  r="116.471"
                  fill="url(#_Radial10)"
                ></circle>
              </g>
            </g>
            </g>
          </g>
          <defs>
            <image
              id="_Image3"
              width="40"
              height="48"
              xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAwCAYAAABjezibAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAC+UlEQVRYhc3Y22oVMRQG4H9t5rWsUtgoKgURSimICCI+gw+gIhbxJXoCrzzrnT7Y70WyMkkmmRxm9rbZbNpOZpIvmbXWDBWs0A5e32Lq+J9Xf2Xp2IsHyOG0LUV2X1yCxa0X2nxRKyxurdCmk5fitLUgq068/eaA5Cq2oNVAiyccvr1DgiAJAlgbWkJmO7fvDkkCioPFmc/+oJOD995vOQF5UJqO4PguocEfD87uMgb9D6iPFAA4+nDfTOnFWQ0ojMvdQOXRx4eMQTcJOogIBAAhYwLYIwAg0XG6qbUnf60udBFwI+IGTUFjTgqavtbDLoCOO0iAUobmd057kejrhw4QmklFIAyhPmf+FtfuqjnSUkMHEYBC65lCSQISQ8fv3CJKoVMDHSCE2HIY3EaF6oAWCgJi4ZTa298PHbCB2S6YicE5aCJxFEoBxZQYyHpQF4MuU3JQ+6lOHAsFaX60lDEPOonBLFQAMA8t1kaFejtNyZUx+yWjGNwHNAZFyehi3F4TxmAiWXYFDQtSIsYttDoGg35/IR40B6p62mTisT4G3USApnUMFYo9p+9pk4rH7hjMQt0OC8Kdq3/a+OOkY3AJVODKxBif/r601cZ0DHYkSxkaR1odtD0GC8nSAs3tnL+UARva4O5NFmXmoLY/A624xbCneqtLQkWfXEAA8UuNrp3jIrxFjZfofNN3UT8IbBbr9XFGjjuQvm0KFXuyX2pm7kYiEede8UySVJSOeEfi+OqG+juceMUbZIOmjAwgqYlaoYVXPFtm2ktHFrImFIJhvHt9paMITYVGLVRggfDGyjxnu6ENyZKCCgCcftrqcsPGEWb/aWAGcr9X9nO0u4VG5zMaDwSuX34W3X/cJOj1iy/OFQBnkS1QH9IIvXr+NTBNgHuB+jtm+6+efUtassCdQaMdu3z6fdZQBFYhFdIIvXwyj6sGrg29OP1RPW8TsIiMoYkYvDj52TRnM1BbK/T8+FfXXN1AbSXo+ePfi+ZYDATyyKU4APgHaHBuI+Ws6ygAAAAASUVORK5CYII="
            ></image>
            <image
              id="_Image6"
              width="39"
              height="48"
              xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACcAAAAwCAYAAACScGMWAAAACXBIWXMAAA7EAAAOxAGVKw4bAAADSUlEQVRYhc2Yy2oUURCG/zpnXivZCIoIWYgiGGIMhkQUdeFG8gBZCLoQoyEyKEm8IIgLceFKEp/rVLmoc+vLzPRtOimYdNGZ7v76r/rrdA9hwNg6vCNEBCLCdOcr9T1f7xOE2Hh3S6wxMB5OP8CH7e6Qg8GtH9wUSxbWmAiWQx49+NL6WmYIsNtv1sSJgIXBwhARiMBvNd+d3pULgWMRMDOcMNgDATmLQADsTtfl4cf1xpC94dZeX5egGIt4QPbKoagiNG8K2Lvnbry6JpYMJkb7zZKBNRYTY2Covv/y/P3905kMveCuvrwiCqRgkyy3xsKSutfMAMu3B5snFZZeZQ2lDGV1nHJmX17AfyTbSmkf8Ph4o1Lqzsqt7q9E1SaZUqqgjeUNpV5U3nz7duOYAGDSFQ5QlxIYTggkDAJAQnDMgNGcGCBDvkTk/4rPg1gh1/8/ObknRATbBWp1fyUrgd4xkN29z0EAoawMkH1pTo72cEUw7RvyEMjACvsiJEV1/DezHKWc+pU1BLOADIMEcPAlFg8k0LJD9xkY1JWUYrFTeVspV1atHESkpy2XM+wjKpU55ak9ND/YPKFBlAO8OZhB3ggOrIbIjRLzoElmDAIgBFC6/8bKLVJNLyUFUwSnlPfFfpzRf4dbn/uPkrpwwnF8qGpesdiDDCcKod0XoFKvhWikXBPVilF1LkDZtAi5iSMnSHu0nZ77BlcO0GWt6FzywxoxT/2Yu7elcu1V8yFp4KYZG6Z1cnF0MwjTnW8FurnKdQaDmoO9ag6Y41zS8tacYyllDZHGi8IgNwoTnJGYf3r0vcI3E66PahXAzK1uxupRF0tVDsjKy5SWuCwnJpw+/Vn76FZriKFUS4AomCJfzn48+zXzmXLpyoVwYaTkSxzXlzNERbmhVcsjKahD+PfzP3OfxEdTDtDXQwcB/BK3KArky1QtD2sM/r44W/j+Et++xgID9OG0SQzyc0TbONs7b/TWZ4BxVWsToyt3vvev8buyuayqASMr10Y14IIM0TQIGMcQbVUDvHJdDhwjKlDLULHrzVd67jKpOBdkCBX73GyjA/tALh0O6AbYt0VaH9wGcnS4EIsghzBW5xViDFcPcoGyikOB/wcahKCTMQzo/wAAAABJRU5ErkJggg=="
            ></image>
            <image
              id="_Image9"
              width="39"
              height="47"
              xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACcAAAAvCAYAAABg8NNYAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACOUlEQVRYhd2Za0sbQRSG39n1b7USrNYqiApVqhUlimD9oIgS9QeUitbWGy2lUbRFaSkqoqIoCOZnac7xQ9h2N9nLzOxmdugLSy6bzLz7nDN3gZQqnRaZSYBJgIjxcaAs0pbpSbugpfMxZnLABPjNETM+De5mYtDR+VPpcoTj7s8cjcXel1WLyo/nrwZZsAtkUnWypPDP3vSxQy4cdiG49uqQi6iwEhGYGJvD+6nCmxjWmdseQ5waFflk03edQVoa5IgZ228PtOk15Nz03cvcSNUrENZmGHt3EN+y4ySAKFMiNJSqYfW++zr6Uzm8jk1hrJdWJ6yjyb1hZQjGzOnI2SlcZzZQJ2mi/EaJnt3kAMAkvdFvA9L0ciE39KVfyuBfcybpySq3nHu9nTyhCJizjV6urbV3oyuWXoM5m+iFkjNpsHs9emy3ohPuWG0LNRhpbqdwlY5eBuytIAcAhQ+tDfRizW21XeTaOKwhBwDP3z8L0Es0t/HiLDd6VpEDgvSkzH1uPzFKzzOoTc7EqkiJiLeR47ALQS4QtzT0loX+JSIRHqpVPFQffde/z1WiQH3W5ZxfSubWOn8Zzb3/hxwArL46NELvfrkitMitdP3IyGB4MffLFQEobrs2W54pT6kILJ4VOV1XUnt/MRc+uc29QUQZAzKYEtYfksiSO5w6Tqy7STknUD/AeU72J39LA0kd1pVe+dOa7+NHSpEy0lp1tlyBTJYhNS38mWB/fjETNoeafEiiq7TGAOAJQsmH2NheF5kAAAAASUVORK5CYII="
            ></image>
            <radialGradient
              id="_Radial10"
              cx="0"
              cy="0"
              r="1"
              gradientTransform="matrix(121.335 177.57 -286.608 756.383 2933.37 1939.9)"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0" stopColor="#3C6F72" stopOpacity="0"></stop>
              <stop offset="0.56" stopColor="#3B686B" stopOpacity="0"></stop>
              <stop offset="1" stopColor="#354D4F" stopOpacity="0"></stop>
            </radialGradient>
          </defs>
        </svg>
      );
}

export default WindMillSvg;
