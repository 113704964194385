import React from "react";

function VillageSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      fillRule="evenodd"
      clipRule="evenodd"
      viewBox="0 0 269 207"
    >
      <g transform="translate(-2617 -2172) translate(0 1351)">
        <g transform="matrix(-1 0 0 1 5862 -18)">
          <g>
            <path
              fill="#FFFAD3"
              d="M3573.06 687.479l.59 78.265-59.42-30.651v-51.759l24.75-35.926 25.7 39.777 8.38.294z"
              transform="matrix(.4948 0 0 .64943 1293.04 428.054)"
            ></path>
            <path
              fill="#41B883"
              fillRule="nonzero"
              d="M0 0l-22.669-39.264L-45.338 0h-75.491l98.16-170.02L75.491 0H0z"
              transform="matrix(.4948 0 0 .64943 1293.04 428.054) matrix(-.14918 -.08613 0 -.17225 3564.76 728.977) translate(178.06 235.01)"
            ></path>
            <path
              fill="#34495E"
              fillRule="nonzero"
              d="M0 0l-22.669-39.264L-45.338 0h-36.227l58.896-102.01L36.227 0H0z"
              transform="matrix(.4948 0 0 .64943 1293.04 428.054) matrix(-.14918 -.08613 0 -.17225 3564.76 728.977) translate(178.06 235.01)"
            ></path>
            <path
              fill="#333"
              d="M3528.8 742.609v-28.061c5.05-9.98 10.09-7.739 15.14 5.758v29.938l-15.14-7.635z"
              transform="matrix(.4948 0 0 .64943 1293.04 428.054)"
            ></path>
            <path
              fill="#FFFAE0"
              d="M3651.85 684.369l-78.79 3.11.59 78.265 79.02-2.263-.82-79.112z"
              transform="matrix(.4948 0 0 .64943 1293.04 428.054) matrix(1.00393 0 0 1 -14.345 0)"
            ></path>
            <path
              fill="#333"
              d="M3585.83 734.775l-.57 15.469h21.84l-.34-15.776-20.93.307z"
              transform="matrix(.4948 0 0 .64943 1293.04 428.054) translate(.48 -5.162)"
            ></path>
            <path
              fill="#333"
              d="M3585.83 734.775l-.57 15.469h21.84l-.34-15.776-20.93.307z"
              transform="matrix(.4948 0 0 .64943 1293.04 428.054) translate(36.943 -17.3)"
            ></path>
            <path
              fill="#41B883"
              d="M3664.01 684.369l-90.95 3.11-35.8-53.591 89.54-1.105 37.21 51.586z"
              transform="matrix(.4948 0 0 .64943 1293.04 428.054)"
            ></path>
            <path
              fill="url(#_Linear1)"
              d="M3573.06 687.479h-7.94l-26.42-40.071-24.47 35.882-11.9 1.079 34.93-50.129 35.8 53.239z"
              transform="matrix(.4948 0 0 .64943 1293.04 428.054)"
            ></path>
          </g>
          <g>
            <path
              fill="#FFFAD3"
              d="M3573.06 687.479l.59 78.265-59.42-30.651v-51.759l24.75-35.926 25.7 39.777 8.38.294z"
              transform="matrix(.64943 0 0 .64943 841.492 464.054)"
            ></path>
            <g fillRule="nonzero">
              <path
                fill="#DD0031"
                d="M125 30L31.9 63.2l14.2 123.1L125 230l78.9-43.7 14.2-123.1L125 30z"
                transform="matrix(.64943 0 0 .64943 841.492 464.054) matrix(-.12284 -.07092 0 .14185 3555.52 677.872)"
              ></path>
              <path
                fill="#C3002F"
                d="M125 30v22.2-.1V230l78.9-43.7 14.2-123.1L125 30z"
                transform="matrix(.64943 0 0 .64943 841.492 464.054) matrix(-.12284 -.07092 0 .14185 3555.52 677.872)"
              ></path>
              <path
                fill="#fff"
                d="M125 52.1L66.8 182.6h21.7l11.7-29.2h49.4l11.7 29.2H183L125 52.1zm17 83.3h-34l17-40.9 17 40.9z"
                transform="matrix(.64943 0 0 .64943 841.492 464.054) matrix(-.12284 -.07092 0 .14185 3555.52 677.872)"
              ></path>
            </g>
            <path
              fill="#333"
              d="M3528.8 742.609v-28.061c5.05-9.98 10.09-7.739 15.14 5.758v29.938l-15.14-7.635z"
              transform="matrix(.64943 0 0 .64943 841.492 464.054)"
            ></path>
            <path
              fill="#FFFAE0"
              d="M3651.85 684.369l-78.79 3.11.59 78.265 79.02-2.263-.82-79.112z"
              transform="matrix(.64943 0 0 .64943 841.492 464.054) matrix(1.00393 0 0 1 -14.345 0)"
            ></path>
            <path
              fill="#333"
              d="M3585.83 734.775l-.57 15.469h21.84l-.34-15.776-20.93.307z"
              transform="matrix(.64943 0 0 .64943 841.492 464.054) translate(.48 -5.162)"
            ></path>
            <path
              fill="#333"
              d="M3585.83 734.775l-.57 15.469h21.84l-.34-15.776-20.93.307z"
              transform="matrix(.64943 0 0 .64943 841.492 464.054) translate(36.943 -17.3)"
            ></path>
            <path
              fill="#DC4667"
              d="M3664.01 684.369l-90.95 3.11-35.8-53.591 89.54-1.105 37.21 51.586z"
              transform="matrix(.64943 0 0 .64943 841.492 464.054)"
            ></path>
            <path
              fill="url(#_Linear2)"
              d="M3573.06 687.479h-7.94l-26.42-40.071-24.47 35.882-11.9 1.079 34.93-50.129 35.8 53.239z"
              transform="matrix(.64943 0 0 .64943 841.492 464.054)"
            ></path>
          </g>
          <g>
            <path
              fill="#FFFAD3"
              d="M3573.06 687.479l.59 78.265-59.42-30.651v-51.759l24.75-35.926 25.7 39.777 8.38.294z"
              transform="matrix(.64943 0 0 .64943 865.492 542.054)"
            ></path>
            <path
              fill="#333"
              d="M3528.8 742.609v-28.061c5.05-9.98 10.09-7.739 15.14 5.758v29.938l-15.14-7.635z"
              transform="matrix(.64943 0 0 .64943 865.492 542.054)"
            ></path>
            <path
              fill="#FFFAE0"
              d="M3651.85 684.369l-78.79 3.11.59 78.265 79.02-2.263-.82-79.112z"
              transform="matrix(.64943 0 0 .64943 865.492 542.054) matrix(1.00393 0 0 1 -14.345 0)"
            ></path>
            <path
              fill="#333"
              d="M3585.83 734.775l-.57 15.469h21.84l-.34-15.776-20.93.307z"
              transform="matrix(.64943 0 0 .64943 865.492 542.054) translate(.48 -5.162)"
            ></path>
            <path
              fill="#333"
              d="M3585.83 734.775l-.57 15.469h21.84l-.34-15.776-20.93.307z"
              transform="matrix(.64943 0 0 .64943 865.492 542.054) translate(36.943 -17.3)"
            ></path>
            <path
              fill="#007390"
              d="M3664.01 684.369l-90.95 3.11-35.8-53.591 89.54-1.105 37.21 51.586z"
              transform="matrix(.64943 0 0 .64943 865.492 542.054)"
            ></path>
            <path
              fill="url(#_Linear3)"
              d="M3573.06 687.479h-7.94l-26.42-40.071-24.47 35.882-11.9 1.079 34.93-50.129 35.8 53.239z"
              transform="matrix(.64943 0 0 .64943 865.492 542.054)"
            ></path>
            <circle
              r="2.05"
              fill="#61DAFB"
              transform="matrix(.64943 0 0 .64943 865.492 542.054) matrix(-1.18986 -.68697 0 1.37393 3555.28 675.99) translate(11.5 10.232)"
            ></circle>
            <g transform="matrix(.64943 0 0 .64943 865.492 542.054) matrix(-1.18986 -.68697 0 1.37393 3555.28 675.99) translate(11.5 10.232)">
              <ellipse
                fill="none"
                stroke="#61DAFB"
                strokeWidth="1"
                rx="11"
                ry="4.2"
              ></ellipse>
              <ellipse
                fill="none"
                stroke="#61DAFB"
                strokeWidth="1"
                rx="11"
                ry="4.2"
                transform="rotate(60)"
              ></ellipse>
              <ellipse
                fill="none"
                stroke="#61DAFB"
                strokeWidth="1"
                rx="11"
                ry="4.2"
                transform="rotate(120)"
              ></ellipse>
            </g>
          </g>
          <g>
            <path
              fill="#FFFAD3"
              d="M3573.06 687.479l.59 78.265-59.42-30.651v-51.759l24.75-35.926 25.7 39.777 8.38.294z"
              transform="translate(-749.86 230.831) scale(1.06382)"
            ></path>
            <path
              fill="#333"
              d="M3528.8 742.609v-28.061c5.05-9.98 10.09-7.739 15.14 5.758v29.938l-15.14-7.635z"
              transform="translate(-749.86 230.831) scale(1.06382)"
            ></path>
            <path
              fill="#FFFAE0"
              d="M3651.85 684.369l-78.79 3.11.59 78.265 79.02-2.263-.82-79.112z"
              transform="translate(-749.86 230.831) scale(1.06382) matrix(1.00393 0 0 1 -14.345 0)"
            ></path>
            <path
              fill="#333"
              d="M3585.83 734.775l-.57 15.469h21.84l-.34-15.776-20.93.307z"
              transform="translate(-749.86 230.831) scale(1.06382) translate(.48 -5.162)"
            ></path>
            <path
              fill="#333"
              d="M3585.83 734.775l-.57 15.469h21.84l-.34-15.776-20.93.307z"
              transform="translate(-749.86 230.831) scale(1.06382) translate(36.943 -17.3)"
            ></path>
            <path
              fill="#F7E018"
              d="M3664.01 684.369l-90.95 3.11-35.8-53.591 89.54-1.105 37.21 51.586z"
              transform="translate(-749.86 230.831) scale(1.06382)"
            ></path>
            <g transform="translate(-749.86 230.831) scale(1.06382) matrix(-1.81046 -1.04527 0 2.09054 10224.3 2947.64)">
              <clipPath id="_clip4">
                <path
                  d="M3690.79 770.009c-.46 0-.88-.089-1.26-.267a2.164 2.164 0 01-.91-.793c-.22-.35-.33-.781-.33-1.292v-2.561h2.02v2.439c0 .178.04.322.11.43.07.108.18.163.34.163.31 0 .46-.188.46-.564v-7.87h2.06v7.667c0 .542-.1 1.011-.32 1.408-.21.397-.5.703-.88.918-.37.215-.81.322-1.29.322zm5.71 0c-.94 0-1.61-.232-2.02-.697-.41-.464-.62-1.204-.62-2.218v-.999h2.02v1.278c0 .236.04.421.11.554.07.134.19.201.37.201.19 0 .32-.055.39-.163.07-.108.11-.287.11-.534 0-.314-.03-.576-.1-.787a1.767 1.767 0 00-.32-.604 6.305 6.305 0 00-.63-.671l-.92-.872c-.69-.646-1.03-1.386-1.03-2.218 0-.872.2-1.536.61-1.993.4-.456.99-.685 1.75-.685.94 0 1.61.25 2 .749.39.5.59 1.259.59 2.277h-2.08v-.703a.432.432 0 00-.12-.325.448.448 0 00-.32-.116c-.17 0-.28.045-.36.136a.51.51 0 00-.11.352c0 .143.04.298.11.465.08.166.23.358.46.575l1.18 1.132c.24.225.45.462.65.712.2.249.36.54.48.871.12.331.18.735.18 1.211 0 .96-.18 1.712-.53 2.256-.36.544-.97.816-1.85.816z"
                  clipRule="nonzero"
                ></path>
              </clipPath>
              <g clipPath="url(#_clip4)">
                <use
                  width="20.393"
                  height="30.951"
                  x="228.241"
                  y="106.012"
                  transform="matrix(.5192 .2596 0 .44965 3573.21 650.074) scale(.9711 .99843)"
                  xlinkHref="#_Image5"
                ></use>
              </g>
            </g>
            <path
              fill="url(#_Linear6)"
              d="M3573.06 687.479h-7.94l-26.42-40.071-24.47 35.882-11.9 1.079 34.93-50.129 35.8 53.239z"
              transform="translate(-749.86 230.831) scale(1.06382)"
            ></path>
          </g>
        </g>
      </g>
      <defs>
        <linearGradient
          id="_Linear1"
          x1="0"
          x2="1"
          y1="0"
          y2="0"
          gradientTransform="matrix(70.7283 0 0 53.2394 3502.33 660.859)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#6B4C35"></stop>
          <stop offset="0.5" stopColor="#5A3D2B"></stop>
          <stop offset="1" stopColor="#543828"></stop>
        </linearGradient>
        <linearGradient
          id="_Linear2"
          x1="0"
          x2="1"
          y1="0"
          y2="0"
          gradientTransform="matrix(70.7283 0 0 53.2394 3502.33 660.859)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#6B4C35"></stop>
          <stop offset="0.5" stopColor="#5A3D2B"></stop>
          <stop offset="1" stopColor="#543828"></stop>
        </linearGradient>
        <linearGradient
          id="_Linear3"
          x1="0"
          x2="1"
          y1="0"
          y2="0"
          gradientTransform="matrix(70.7283 0 0 53.2394 3502.33 660.859)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#6B4C35"></stop>
          <stop offset="0.5" stopColor="#5A3D2B"></stop>
          <stop offset="1" stopColor="#543828"></stop>
        </linearGradient>
        <image
          id="_Image5"
          width="21"
          height="31"
          xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAAfCAYAAAAIjIbwAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAbklEQVRIie3Quw3AMAgE0CPKRuw/AjPFFVYKO+ZXpOAqJKSnA0IgzPx87akadKEWzIx6sCMawbZoBluiFeBEq7CJVoMAcFWDjTbaaKP/RkWE7kpM5zT6xjSp81cgEGy6w0LoCXOhVkxz/KkXBIABkToh2ICKC90AAAAASUVORK5CYII="
        ></image>
        <linearGradient
          id="_Linear6"
          x1="0"
          x2="1"
          y1="0"
          y2="0"
          gradientTransform="matrix(70.7283 0 0 53.2394 3502.33 660.859)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#6B4C35"></stop>
          <stop offset="0.5" stopColor="#5A3D2B"></stop>
          <stop offset="1" stopColor="#543828"></stop>
        </linearGradient>
      </defs>
    </svg>
  );
}

export default VillageSvg;
