import React from "react";
import styles from './layer-two.module.css';

export enum LayerTwoSvgGradient {
    MORNING = 0,
    AFTERNOON = 1,
    EVENING = 2,
    NIGHT = 3
  }
  
  interface LayerTwoSvgProps {
      gradient: LayerTwoSvgGradient
  }

function LayerTwoSvg(props: LayerTwoSvgProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fillRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit="2"
      clipRule="evenodd"
    //   viewBox="0 0 7680 383"
      viewBox="0 0 7680 329"
    >
      <g transform="translate(0 -697.5)">
        <g>
          <path
            fill="#333"
            fillRule="nonzero"
            d="M254.5 49L227 182h50.5l-23-133zM415.5 0L388 133h50.5l-23-133z"
            transform="translate(848.5 697.5)"
          ></path>
          <path
            fill="#333"
            fillRule="nonzero"
            d="M415.5 0L388 133h50.5l-23-133z"
            transform="translate(848.5 697.5) matrix(.40397 0 0 .39126 904.372 120.436)"
          ></path>
          <path
            fill="#333"
            fillRule="nonzero"
            d="M415.5 0L388 133h50.5l-23-133z"
            transform="translate(848.5 697.5) matrix(.40397 0 0 .39126 930.28 140.946)"
          ></path>
          <path
            fill="#333"
            fillRule="nonzero"
            d="M415.5 0L388 133h50.5l-23-133z"
            transform="translate(848.5 697.5) matrix(.40397 0 0 .39126 1139.7 146.344)"
          ></path>
          <path
            fill="#333"
            fillRule="nonzero"
            d="M415.5 0L388 133h50.5l-23-133z"
            transform="translate(848.5 697.5) matrix(.40397 0 0 .39126 1156.97 138.787)"
          ></path>
          <path
            fill="#333"
            fillRule="nonzero"
            d="M415.5 0L388 133h50.5l-23-133z"
            transform="translate(848.5 697.5) matrix(.40397 0 0 .39126 1175.33 127.992)"
          ></path>
          <path
            fill="#333"
            fillRule="nonzero"
            d="M415.5 0L388 133h50.5l-23-133z"
            transform="translate(848.5 697.5) matrix(.40397 0 0 .39126 1192.6 148.503)"
          ></path>
          <path
            fill="#333"
            fillRule="nonzero"
            d="M415.5 0L388 133h50.5l-23-133z"
            transform="translate(848.5 697.5) matrix(.40397 0 0 .39126 1208.79 133.39)"
          ></path>
          <path
            fill="#333"
            fillRule="nonzero"
            d="M415.5 0L388 133h50.5l-23-133z"
            transform="translate(848.5 697.5) matrix(.40397 0 0 .39126 1228.22 130.151)"
          ></path>
          <path
            fill="#333"
            fillRule="nonzero"
            d="M415.5 0L388 133h50.5l-23-133z"
            transform="translate(848.5 697.5) matrix(.40397 0 0 .39126 1245.49 143.105)"
          ></path>
          <path
            fill="#333"
            fillRule="nonzero"
            d="M415.5 0L388 133h50.5l-23-133z"
            transform="translate(848.5 697.5) matrix(.40397 0 0 .39126 1268.16 120.436)"
          ></path>
          <path
            fill="#333"
            fillRule="nonzero"
            d="M415.5 0L388 133h50.5l-23-133z"
            transform="translate(848.5 697.5) matrix(.40397 0 0 .39126 1283.28 147.423)"
          ></path>
          <path
            fill="#333"
            fillRule="nonzero"
            d="M415.5 0L388 133h50.5l-23-133z"
            transform="translate(848.5 697.5) matrix(.40397 0 0 .39126 1295.15 132.31)"
          ></path>
          <path
            fill="#333"
            fillRule="nonzero"
            d="M415.5 0L388 133h50.5l-23-133z"
            transform="translate(848.5 697.5) matrix(.40397 0 0 .39126 1295.15 132.31)"
          ></path>
          <path
            fill="#333"
            fillRule="nonzero"
            d="M415.5 0L388 133h50.5l-23-133z"
            transform="translate(848.5 697.5) matrix(.40397 0 0 .39126 1305.95 133.39)"
          ></path>
          <path
            fill="#333"
            fillRule="nonzero"
            d="M415.5 0L388 133h50.5l-23-133z"
            transform="translate(848.5 697.5) matrix(.40397 0 0 .39126 1322.14 124.754)"
          ></path>
          <path
            fill="#333"
            fillRule="nonzero"
            d="M415.5 0L388 133h50.5l-23-133z"
            transform="translate(848.5 697.5) matrix(.40397 0 0 .39126 1339.41 137.708)"
          ></path>
          <path
            fill="#333"
            fillRule="nonzero"
            d="M415.5 0L388 133h50.5l-23-133z"
            transform="translate(848.5 697.5) matrix(.40397 0 0 .39126 1355.6 148.503)"
          ></path>
          <path
            fill="#333"
            fillRule="nonzero"
            d="M415.5 0L388 133h50.5l-23-133z"
            transform="translate(848.5 697.5) matrix(.40397 0 0 .39126 1366.4 161.457)"
          ></path>
          <path
            fill="#333"
            fillRule="nonzero"
            d="M415.5 0L388 133h50.5l-23-133z"
            transform="translate(848.5 697.5) matrix(.40397 0 0 .39126 1478.66 164.695)"
          ></path>
          <path
            fill="#333"
            fillRule="nonzero"
            d="M415.5 0L388 133h50.5l-23-133z"
            transform="translate(848.5 697.5) matrix(.40397 0 0 .39126 1355.6 148.503)"
          ></path>
          <path
            fill="#333"
            fillRule="nonzero"
            d="M415.5 0L388 133h50.5l-23-133z"
            transform="translate(848.5 726.5) matrix(.40397 0 0 .39126 1493.78 154.98)"
          ></path>
          <path
            fill="#333"
            fillRule="nonzero"
            d="M415.5 0L388 133h50.5l-23-133z"
            transform="translate(848.5 697.5) matrix(.40397 0 0 .39126 1507.81 169.013)"
          ></path>
          <path
            fill="#333"
            fillRule="nonzero"
            d="M415.5 0L388 133h50.5l-23-133z"
            transform="translate(848.5 697.5) matrix(.40397 0 0 .39126 1631.95 196.206)"
          ></path>
          <path
            fill="#333"
            fillRule="nonzero"
            d="M415.5 0L388 133h50.5l-23-133z"
            transform="translate(848.5 697.5) matrix(.40397 0 0 .39126 2498.79 99.925)"
          ></path>
          <path
            fill="#333"
            fillRule="nonzero"
            d="M415.5 0L388 133h50.5l-23-133z"
            transform="translate(848.5 697.5) matrix(.40397 0 0 .39126 2521.46 112.879)"
          ></path>
          <path
            fill="#333"
            fillRule="nonzero"
            d="M415.5 0L388 133h50.5l-23-133z"
            transform="translate(848.5 697.5) matrix(.40397 0 0 .39126 2521.46 112.879)"
          ></path>
          <path
            fill="#333"
            fillRule="nonzero"
            d="M415.5 0L388 133h50.5l-23-133z"
            transform="translate(848.5 697.5) matrix(.40397 0 0 .39126 2748.15 96.687)"
          ></path>
          <path
            fill="#333"
            fillRule="nonzero"
            d="M415.5 0L388 133h50.5l-23-133z"
            transform="translate(848.5 697.5) matrix(.40397 0 0 .39126 2731.96 85.892)"
          ></path>
          <path
            fill="#333"
            fillRule="nonzero"
            d="M415.5 0L388 133h50.5l-23-133z"
            transform="translate(848.5 697.5) matrix(.40397 0 0 .39126 2764.35 117.197)"
          ></path>
          <path
            fill="#333"
            fillRule="nonzero"
            d="M415.5 0L388 133h50.5l-23-133z"
            transform="translate(848.5 697.5) matrix(.40397 0 0 .39126 2871.22 61.064)"
          ></path>
          <path
            fill="#333"
            fillRule="nonzero"
            d="M415.5 0L388 133h50.5l-23-133z"
            transform="translate(848.5 697.5) matrix(.40397 0 0 .39126 2892.81 43.792)"
          ></path>
          <path
            fill="#333"
            fillRule="nonzero"
            d="M415.5 0L388 133h50.5l-23-133z"
            transform="translate(848.5 697.5) matrix(.40397 0 0 .39126 2910.08 45.95)"
          ></path>
          <path
            fill="#333"
            fillRule="nonzero"
            d="M415.5 0L388 133h50.5l-23-133z"
            transform="translate(848.5 697.5) matrix(.40397 0 0 .39126 2910.08 45.95)"
          ></path>
          <path
            fill="#333"
            fillRule="nonzero"
            d="M415.5 0L388 133h50.5l-23-133z"
            transform="translate(848.5 697.5) matrix(.40397 0 0 .39126 2927.35 18.963)"
          ></path>
          <path
            fill="#333"
            fillRule="nonzero"
            d="M415.5 0L388 133h50.5l-23-133z"
            transform="translate(848.5 697.5) matrix(.40397 0 0 .39126 2945.7 16.804)"
          ></path>
          <path
            fill="#333"
            fillRule="nonzero"
            d="M415.5 0L388 133h50.5l-23-133z"
            transform="translate(848.5 697.5) matrix(.40397 0 0 .39126 2961.9 18.963)"
          ></path>
          <path
            fill="#333"
            fillRule="nonzero"
            d="M415.5 0L388 133h50.5l-23-133z"
            transform="translate(848.5 697.5) matrix(.40397 0 0 .39126 2978.09 2.77)"
          ></path>
          <path
            fill="#333"
            fillRule="nonzero"
            d="M415.5 0L388 133h50.5l-23-133z"
            transform="translate(848.5 697.5) matrix(.40397 0 0 .39126 2993.2 11.407)"
          ></path>
          <path
            fill="#333"
            fillRule="nonzero"
            d="M415.5 0L388 133h50.5l-23-133z"
            transform="translate(848.5 697.5) matrix(.40397 0 0 .39126 3006.15 20.043)"
          ></path>
          <path
            fill="#333"
            fillRule="nonzero"
            d="M415.5 0L388 133h50.5l-23-133z"
            transform="translate(848.5 697.5) matrix(.40397 0 0 .39126 3233.93 16.804)"
          ></path>
          <path
            fill="#333"
            fillRule="nonzero"
            d="M415.5 0L388 133h50.5l-23-133z"
            transform="translate(848.5 697.5) matrix(.40397 0 0 .39126 3233.93 16.804)"
          ></path>
          <path
            fill="#333"
            fillRule="nonzero"
            d="M415.5 0L388 133h50.5l-23-133z"
            transform="translate(848.5 697.5) matrix(.40397 0 0 .39126 3254.44 28.679)"
          ></path>
          <path
            fill="#333"
            fillRule="nonzero"
            d="M415.5 0L388 133h50.5l-23-133z"
            transform="translate(848.5 697.5) matrix(.40397 0 0 .39126 3273.87 21.122)"
          ></path>
          <path
            fill="#333"
            fillRule="nonzero"
            d="M415.5 0L388 133h50.5l-23-133z"
            transform="translate(848.5 697.5) matrix(.40397 0 0 .39126 3337.56 53.507)"
          ></path>
          <path
            fill="#333"
            fillRule="nonzero"
            d="M415.5 0L388 133h50.5l-23-133z"
            transform="translate(848.5 697.5) matrix(.40397 0 0 .39126 3361.31 65.382)"
          ></path>
          <path
            fill="#333"
            fillRule="nonzero"
            d="M415.5 0L388 133h50.5l-23-133z"
            transform="translate(848.5 697.5) matrix(.40397 0 0 .39126 3392.61 48.11)"
          ></path>
          <path
            fill="#333"
            fillRule="nonzero"
            d="M415.5 0L388 133h50.5l-23-133z"
            transform="translate(848.5 697.5) matrix(.40397 0 0 .39126 3630.1 66.461)"
          ></path>
          <path
            fill="#333"
            fillRule="nonzero"
            d="M415.5 0L388 133h50.5l-23-133z"
            transform="translate(848.5 697.5) matrix(.40397 0 0 .39126 3648.46 83.733)"
          ></path>
          <path
            fill="#333"
            fillRule="nonzero"
            d="M415.5 0L388 133h50.5l-23-133z"
            transform="translate(848.5 697.5) matrix(.40397 0 0 .39126 3796.35 117.197)"
          ></path>
          <path
            fill="#333"
            fillRule="nonzero"
            d="M415.5 0L388 133h50.5l-23-133z"
            transform="translate(848.5 697.5) matrix(.40397 0 0 .39126 3822.26 140.946)"
          ></path>
          <path
            fill="#333"
            fillRule="nonzero"
            d="M415.5 0L388 133h50.5l-23-133z"
            transform="translate(848.5 697.5) matrix(.40397 0 0 .39126 4210.87 133.39)"
          ></path>
          <path
            fill="#333"
            fillRule="nonzero"
            d="M415.5 0L388 133h50.5l-23-133z"
            transform="translate(848.5 697.5) matrix(.40397 0 0 .39126 4235.7 148.503)"
          ></path>
          <path
            fill="#333"
            fillRule="nonzero"
            d="M415.5 0L388 133h50.5l-23-133z"
            transform="translate(848.5 697.5) matrix(.40397 0 0 .39126 4352.29 92.369)"
          ></path>
          <path
            fill="#333"
            fillRule="nonzero"
            d="M415.5 0L388 133h50.5l-23-133z"
            transform="translate(848.5 697.5) matrix(.40397 0 0 .39126 4371.72 102.085)"
          ></path>
          <path
            fill="#333"
            fillRule="nonzero"
            d="M415.5 0L388 133h50.5l-23-133z"
            transform="translate(848.5 697.5) matrix(.40397 0 0 .39126 4393.31 80.495)"
          ></path>
          <path
            fill="#333"
            fillRule="nonzero"
            d="M415.5 0L388 133h50.5l-23-133z"
            transform="translate(848.5 697.5) matrix(.40397 0 0 .39126 4417.06 92.369)"
          ></path>
          <path
            fill="#333"
            fillRule="nonzero"
            d="M415.5 0L388 133h50.5l-23-133z"
            transform="translate(848.5 697.5) matrix(.40397 0 0 .39126 4664.26 49.19)"
          ></path>
          <path
            fill="#333"
            fillRule="nonzero"
            d="M415.5 0L388 133h50.5l-23-133z"
            transform="translate(848.5 697.5) matrix(.40397 0 0 .39126 4664.26 49.19)"
          ></path>
          <path
            fill="#333"
            fillRule="nonzero"
            d="M415.5 0L388 133h50.5l-23-133z"
            transform="translate(848.5 697.5) matrix(.40397 0 0 .39126 4688.01 66.461)"
          ></path>
          <path
            fill="#333"
            fillRule="nonzero"
            d="M415.5 0L388 133h50.5l-23-133z"
            transform="translate(848.5 697.5) matrix(.40397 0 0 .39126 4752.78 58.905)"
          ></path>
          <path
            fill="#333"
            fillRule="nonzero"
            d="M415.5 0L388 133h50.5l-23-133z"
            transform="translate(848.5 697.5) matrix(.40397 0 0 .39126 4856.41 72.938)"
          ></path>
          <path
            fill="#333"
            fillRule="nonzero"
            d="M415.5 0L388 133h50.5l-23-133z"
            transform="translate(848.5 697.5) matrix(.40397 0 0 .39126 4878 84.813)"
          ></path>
          <path
            fill="#333"
            fillRule="nonzero"
            d="M415.5 0L388 133h50.5l-23-133z"
            transform="translate(848.5 697.5) matrix(.40397 0 0 .39126 4892.04 76.177)"
          ></path>
          <path
            fill="#333"
            fillRule="nonzero"
            d="M415.5 0L388 133h50.5l-23-133z"
            transform="translate(848.5 697.5) matrix(.40397 0 0 .39126 4901.75 89.13)"
          ></path>
          <path
            fill="#333"
            fillRule="nonzero"
            d="M415.5 0L388 133h50.5l-23-133z"
            transform="translate(848.5 697.5) matrix(.40397 0 0 .39126 4916.87 76.177)"
          ></path>
          <path
            fill="#333"
            fillRule="nonzero"
            d="M415.5 0L388 133h50.5l-23-133z"
            transform="translate(848.5 697.5) matrix(.40397 0 0 .39126 5017.26 111.8)"
          ></path>
          <path
            fill="#333"
            fillRule="nonzero"
            d="M415.5 0L388 133h50.5l-23-133z"
            transform="translate(848.5 697.5) matrix(.40397 0 0 .39126 5153.28 115.038)"
          ></path>
          <path
            fill="#333"
            fillRule="nonzero"
            d="M415.5 0L388 133h50.5l-23-133z"
            transform="translate(848.5 697.5) matrix(.40397 0 0 .39126 5169.47 107.482)"
          ></path>
          <path
            fill="#333"
            fillRule="nonzero"
            d="M415.5 0L388 133h50.5l-23-133z"
            transform="translate(848.5 697.5) matrix(.40397 0 0 .39126 5169.47 107.482)"
          ></path>
          <path
            fill="#333"
            fillRule="nonzero"
            d="M415.5 0L388 133h50.5l-23-133z"
            transform="translate(848.5 697.5) matrix(.40397 0 0 .39126 5186.74 113.959)"
          ></path>
          <path
            fill="#333"
            fillRule="nonzero"
            d="M415.5 0L388 133h50.5l-23-133z"
            transform="translate(848.5 697.5) matrix(.40397 0 0 .39126 5186.74 113.959)"
          ></path>
          <path
            fill="#333"
            fillRule="nonzero"
            d="M415.5 0L388 133h50.5l-23-133z"
            transform="translate(848.5 697.5) matrix(.40397 0 0 .39126 5269.86 57.825)"
          ></path>
          <path
            fill="#333"
            fillRule="nonzero"
            d="M415.5 0L388 133h50.5l-23-133z"
            transform="translate(848.5 697.5) matrix(.40397 0 0 .39126 5269.86 57.825)"
          ></path>
          <path
            fill="#333"
            fillRule="nonzero"
            d="M415.5 0L388 133h50.5l-23-133z"
            transform="translate(848.5 697.5) matrix(.40397 0 0 .39126 5289.29 71.859)"
          ></path>
          <path
            fill="#333"
            fillRule="nonzero"
            d="M415.5 0L388 133h50.5l-23-133z"
            transform="translate(848.5 697.5) matrix(.40397 0 0 .39126 5307.64 50.269)"
          ></path>
          <path
            fill="#333"
            fillRule="nonzero"
            d="M415.5 0L388 133h50.5l-23-133z"
            transform="translate(848.5 697.5) matrix(.40397 0 0 .39126 5326 55.666)"
          ></path>
          <path
            fill="#333"
            fillRule="nonzero"
            d="M415.5 0L388 133h50.5l-23-133z"
            transform="translate(848.5 697.5) matrix(.40397 0 0 .39126 5326 55.666)"
          ></path>
          <path
            fill="#333"
            fillRule="nonzero"
            d="M415.5 0L388 133h50.5l-23-133z"
            transform="translate(848.5 697.5) matrix(.40397 0 0 .39126 5452.3 21.122)"
          ></path>
          <path
            fill="#333"
            fillRule="nonzero"
            d="M415.5 0L388 133h50.5l-23-133z"
            transform="translate(848.5 697.5) matrix(.40397 0 0 .39126 5452.3 21.122)"
          ></path>
          <path
            fill="#333"
            fillRule="nonzero"
            d="M415.5 0L388 133h50.5l-23-133z"
            transform="translate(848.5 697.5) matrix(.40397 0 0 .39126 5670.36 18.963)"
          ></path>
          <path
            fill="#333"
            fillRule="nonzero"
            d="M415.5 0L388 133h50.5l-23-133z"
            transform="translate(848.5 697.5) matrix(.40397 0 0 .39126 5693.03 29.758)"
          ></path>
          <path
            fill="#333"
            fillRule="nonzero"
            d="M415.5 0L388 133h50.5l-23-133z"
            transform="translate(848.5 697.5) matrix(.40397 0 0 .39126 5861.43 51.348)"
          ></path>
          <path
            fill="#333"
            fillRule="nonzero"
            d="M415.5 0L388 133h50.5l-23-133z"
            transform="translate(848.5 697.5) matrix(.40397 0 0 .39126 5861.43 51.348)"
          ></path>
          <path
            fill="#333"
            fillRule="nonzero"
            d="M415.5 0L388 133h50.5l-23-133z"
            transform="translate(848.5 697.5) matrix(.40397 0 0 .39126 5872.22 61.064)"
          ></path>
          <path
            fill="#333"
            fillRule="nonzero"
            d="M415.5 0L388 133h50.5l-23-133z"
            transform="translate(848.5 697.5) matrix(.40397 0 0 .39126 5872.22 61.064)"
          ></path>
          <path
            fill="#333"
            fillRule="nonzero"
            d="M415.5 0L388 133h50.5l-23-133z"
            transform="translate(848.5 697.5) matrix(.40397 0 0 .39126 5886.26 70.78)"
          ></path>
          <path
            fill="#333"
            fillRule="nonzero"
            d="M415.5 0L388 133h50.5l-23-133z"
            transform="translate(848.5 697.5) matrix(.40397 0 0 .39126 5886.26 70.78)"
          ></path>
          <path
            fill="#333"
            fillRule="nonzero"
            d="M415.5 0L388 133h50.5l-23-133z"
            transform="translate(848.5 697.5) matrix(.40397 0 0 .39126 5903.53 57.825)"
          ></path>
          <path
            fill="#333"
            fillRule="nonzero"
            d="M415.5 0L388 133h50.5l-23-133z"
            transform="translate(848.5 697.5) matrix(.40397 0 0 .39126 5886.26 70.78)"
          ></path>
          <path
            fill="#333"
            fillRule="nonzero"
            d="M415.5 0L388 133h50.5l-23-133z"
            transform="translate(848.5 697.5) matrix(.40397 0 0 .39126 5919.72 68.62)"
          ></path>
          <path
            fill="#333"
            fillRule="nonzero"
            d="M415.5 0L388 133h50.5l-23-133z"
            transform="translate(848.5 697.5) matrix(.40397 0 0 .39126 5939.15 61.064)"
          ></path>
          <path
            fill="#333"
            fillRule="nonzero"
            d="M415.5 0L388 133h50.5l-23-133z"
            transform="translate(848.5 697.5) matrix(.40397 0 0 .39126 6076.25 103.164)"
          ></path>
          <path
            fill="#333"
            fillRule="nonzero"
            d="M415.5 0L388 133h50.5l-23-133z"
            transform="translate(848.5 697.5) matrix(.40397 0 0 .39126 6092.44 97.766)"
          ></path>
          <path
            fill="#333"
            fillRule="nonzero"
            d="M415.5 0L388 133h50.5l-23-133z"
            transform="translate(848.5 697.5) matrix(.40397 0 0 .39126 6092.44 97.766)"
          ></path>
          <path
            fill="#333"
            fillRule="nonzero"
            d="M415.5 0L388 133h50.5l-23-133z"
            transform="translate(848.5 697.5) matrix(.40397 0 0 .39126 6108.63 120.436)"
          ></path>
          <path
            fill="#333"
            fillRule="nonzero"
            d="M415.5 0L388 133h50.5l-23-133z"
            transform="translate(848.5 697.5) matrix(.40397 0 0 .39126 6287.75 179.808)"
          ></path>
          <path
            fill="#333"
            fillRule="nonzero"
            d="M415.5 0L388 133h50.5l-23-133z"
            transform="translate(848.5 697.5) matrix(.40397 0 0 .39126 2477.69 118.277)"
          ></path>
          <path
            fill="#333"
            fillRule="nonzero"
            d="M446.5 23L419 156h50.5l-23-133zM1024.5 106L997 239h50.5l-23-133zM280 64l-18 52h32l-14-52z"
            transform="translate(848.5 697.5)"
          ></path>
          {/* <path
            fill="url(#_Linear1)"
            d="M5928.78 971.621c1.07-38.7 214.49-108.582 349.32-166.069 128.35-54.729 248.79-48.729 351.47-52.552 231.55-8.62 559.16 112.401 722.35 166.901 95.66 31.95 256.74 160.099 256.74 160.099H6534.69s-607.83-38.9-605.91-108.379z"
            transform="translate(848.5 697.5) translate(-848.5 -697.5)"
          ></path> */}
          <path
            fill="#333"
            d="M3809.84 885.2c-9.95-48.107 140.11-142.793 232.55-157.048 102.3-15.773 296.3 53.694 381.2 62.408 42.64 4.377 85.95-17.375 128.19-10.12 69.72 11.974 217.91 53.306 290.12 81.961 52.37 20.785 198.36 78.865 143.12 89.968-105.98 21.298-583.5 54.561-778.97 37.818-138.73-11.88-387.36-62.242-396.21-104.987z"
            transform="translate(848.5 697.5) translate(-848.5 -697.5)"
          ></path>
          <path
            className={`${styles.gradient}`}
            style={{opacity: props.gradient == LayerTwoSvgGradient.MORNING ? 1 : 0}}
            fill="url(#gradient-2-morning)"
            d="M3809.84 885.2c-9.95-48.107 140.11-142.793 232.55-157.048 102.3-15.773 296.3 53.694 381.2 62.408 42.64 4.377 85.95-17.375 128.19-10.12 69.72 11.974 217.91 53.306 290.12 81.961 52.37 20.785 198.36 78.865 143.12 89.968-105.98 21.298-583.5 54.561-778.97 37.818-138.73-11.88-387.36-62.242-396.21-104.987z"
            transform="translate(848.5 697.5) translate(-848.5 -697.5)"
          ></path>
          <path
            className={`${styles.gradient}`}
            style={{opacity: props.gradient == LayerTwoSvgGradient.AFTERNOON ? 1 : 0}}
            fill="url(#gradient-2-afternoon)"
            d="M3809.84 885.2c-9.95-48.107 140.11-142.793 232.55-157.048 102.3-15.773 296.3 53.694 381.2 62.408 42.64 4.377 85.95-17.375 128.19-10.12 69.72 11.974 217.91 53.306 290.12 81.961 52.37 20.785 198.36 78.865 143.12 89.968-105.98 21.298-583.5 54.561-778.97 37.818-138.73-11.88-387.36-62.242-396.21-104.987z"
            transform="translate(848.5 697.5) translate(-848.5 -697.5)"
          ></path>
          <path
            className={`${styles.gradient}`}
            style={{opacity: props.gradient == LayerTwoSvgGradient.EVENING ? 1 : 0}}
            fill="url(#gradient-2-evening)"
            d="M3809.84 885.2c-9.95-48.107 140.11-142.793 232.55-157.048 102.3-15.773 296.3 53.694 381.2 62.408 42.64 4.377 85.95-17.375 128.19-10.12 69.72 11.974 217.91 53.306 290.12 81.961 52.37 20.785 198.36 78.865 143.12 89.968-105.98 21.298-583.5 54.561-778.97 37.818-138.73-11.88-387.36-62.242-396.21-104.987z"
            transform="translate(848.5 697.5) translate(-848.5 -697.5)"
          ></path>
          <path
            className={`${styles.gradient}`}
            style={{opacity: props.gradient == LayerTwoSvgGradient.NIGHT ? 1 : 0}}
            fill="url(#gradient-2-night)"
            d="M3809.84 885.2c-9.95-48.107 140.11-142.793 232.55-157.048 102.3-15.773 296.3 53.694 381.2 62.408 42.64 4.377 85.95-17.375 128.19-10.12 69.72 11.974 217.91 53.306 290.12 81.961 52.37 20.785 198.36 78.865 143.12 89.968-105.98 21.298-583.5 54.561-778.97 37.818-138.73-11.88-387.36-62.242-396.21-104.987z"
            transform="translate(848.5 697.5) translate(-848.5 -697.5)"
          ></path>
          <path
            fill="#333"
            d="M5232.76 876.095c72.48-22.984 269.13-104.182 408.5-94.094 221.82 16.055 630.06 94.203 922.42 190.424C6760.36 1037.15 7680 1021.55 7680 1021.55V1080H0s663.167-191.667 926.5-246c214.93-44.347 399.34-118.86 653.5-80 137.31 20.995 340 104.5 340 104.5s96.41 35.755 146.82 36.286c61.71.65 148.46-38.687 223.46-32.385 109.21 9.175 284.97 84.613 431.8 87.439 181.37 3.491 633.85-56.644 656.43-66.493 51.61-22.512 207.27-72.529 312.97-61.24 146.05 15.6 417.1 143.958 563.34 154.836 405.35 30.147 961.7-95.697 977.94-100.848z"
            transform="translate(848.5 697.5) translate(-848.5 -697.5)"
          ></path>
          <path
            className={`${styles.gradient}`}
            fill="url(#gradient-1-morning)"
            style={{opacity: props.gradient == LayerTwoSvgGradient.MORNING ? 1 : 0}}
            d="M5232.76 876.095c72.48-22.984 269.13-104.182 408.5-94.094 221.82 16.055 630.06 94.203 922.42 190.424C6760.36 1037.15 7680 1021.55 7680 1021.55V1080H0s663.167-191.667 926.5-246c214.93-44.347 399.34-118.86 653.5-80 137.31 20.995 340 104.5 340 104.5s96.41 35.755 146.82 36.286c61.71.65 148.46-38.687 223.46-32.385 109.21 9.175 284.97 84.613 431.8 87.439 181.37 3.491 633.85-56.644 656.43-66.493 51.61-22.512 207.27-72.529 312.97-61.24 146.05 15.6 417.1 143.958 563.34 154.836 405.35 30.147 961.7-95.697 977.94-100.848z"
            transform="translate(848.5 697.5) translate(-848.5 -697.5)"
          ></path>
          <path
            className={`${styles.gradient}`}
            fill="url(#gradient-1-afternoon)"
            style={{opacity: props.gradient == LayerTwoSvgGradient.AFTERNOON ? 1 : 0}}
            d="M5232.76 876.095c72.48-22.984 269.13-104.182 408.5-94.094 221.82 16.055 630.06 94.203 922.42 190.424C6760.36 1037.15 7680 1021.55 7680 1021.55V1080H0s663.167-191.667 926.5-246c214.93-44.347 399.34-118.86 653.5-80 137.31 20.995 340 104.5 340 104.5s96.41 35.755 146.82 36.286c61.71.65 148.46-38.687 223.46-32.385 109.21 9.175 284.97 84.613 431.8 87.439 181.37 3.491 633.85-56.644 656.43-66.493 51.61-22.512 207.27-72.529 312.97-61.24 146.05 15.6 417.1 143.958 563.34 154.836 405.35 30.147 961.7-95.697 977.94-100.848z"
            transform="translate(848.5 697.5) translate(-848.5 -697.5)"
          ></path>
          <path
            className={`${styles.gradient}`}
            fill="url(#gradient-1-evening)"
            style={{opacity: props.gradient == LayerTwoSvgGradient.EVENING ? 1 : 0}}
            d="M5232.76 876.095c72.48-22.984 269.13-104.182 408.5-94.094 221.82 16.055 630.06 94.203 922.42 190.424C6760.36 1037.15 7680 1021.55 7680 1021.55V1080H0s663.167-191.667 926.5-246c214.93-44.347 399.34-118.86 653.5-80 137.31 20.995 340 104.5 340 104.5s96.41 35.755 146.82 36.286c61.71.65 148.46-38.687 223.46-32.385 109.21 9.175 284.97 84.613 431.8 87.439 181.37 3.491 633.85-56.644 656.43-66.493 51.61-22.512 207.27-72.529 312.97-61.24 146.05 15.6 417.1 143.958 563.34 154.836 405.35 30.147 961.7-95.697 977.94-100.848z"
            transform="translate(848.5 697.5) translate(-848.5 -697.5)"
          ></path>
          <path
            className={`${styles.gradient}`}
            fill="url(#gradient-1-night)"
            style={{opacity: props.gradient == LayerTwoSvgGradient.NIGHT ? 1 : 0}}
            d="M5232.76 876.095c72.48-22.984 269.13-104.182 408.5-94.094 221.82 16.055 630.06 94.203 922.42 190.424C6760.36 1037.15 7680 1021.55 7680 1021.55V1080H0s663.167-191.667 926.5-246c214.93-44.347 399.34-118.86 653.5-80 137.31 20.995 340 104.5 340 104.5s96.41 35.755 146.82 36.286c61.71.65 148.46-38.687 223.46-32.385 109.21 9.175 284.97 84.613 431.8 87.439 181.37 3.491 633.85-56.644 656.43-66.493 51.61-22.512 207.27-72.529 312.97-61.24 146.05 15.6 417.1 143.958 563.34 154.836 405.35 30.147 961.7-95.697 977.94-100.848z"
            transform="translate(848.5 697.5) translate(-848.5 -697.5)"
          ></path>
        </g>
      </g>
      <defs>
        <linearGradient
          id="_Linear1"
          x1="0"
          x2="1"
          y1="0"
          y2="0"
          gradientTransform="matrix(1154.07 0 0 269.246 6164.03 886.926)"
          gradientUnits="userSpaceOnUse"
        >
            <stop offset="0" stopColor="var(--gradient-1-evening-start)"/>
            <stop offset="1" stopColor="var(--gradient-1-evening-end)"/>
        </linearGradient>
        <radialGradient
          id="gradient-2-morning"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="rotate(-27.787 3785.648 -8132.896) scale(742.544)"
          gradientUnits="userSpaceOnUse"
        >
            <stop offset="0" stopColor="var(--gradient-1-morning-start)"/>
            <stop offset="1" stopColor="var(--gradient-1-morning-end)"/>
        </radialGradient>
        <radialGradient
          id="gradient-2-afternoon"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="rotate(-27.787 3785.648 -8132.896) scale(742.544)"
          gradientUnits="userSpaceOnUse"
        >
            <stop offset="0" stopColor="var(--gradient-1-afternoon-start)"/>
            <stop offset="1" stopColor="var(--gradient-1-afternoon-end)"/>
        </radialGradient>
        <radialGradient
          id="gradient-2-evening"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="rotate(-27.787 3785.648 -8132.896) scale(742.544)"
          gradientUnits="userSpaceOnUse"
        >
            <stop offset="0" stopColor="var(--gradient-1-evening-start)"/>
            <stop offset="1" stopColor="var(--gradient-1-evening-end)"/>
        </radialGradient>
        <radialGradient
          id="gradient-2-night"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="rotate(-27.787 3785.648 -8132.896) scale(742.544)"
          gradientUnits="userSpaceOnUse"
        >
            <stop offset="0" stopColor="var(--gradient-1-night-start)"/>
            <stop offset="1" stopColor="var(--gradient-1-night-end)"/>
        </radialGradient>
        <radialGradient 
            id="gradient-1-morning" 
            cx="0" 
            cy="0" 
            r="1" 
            gradientUnits="userSpaceOnUse" 
            gradientTransform="matrix(747.869 191.25 -191.25 747.869 910.352 888.75)"
        >
            <stop offset="0" stopColor="var(--gradient-1-morning-start)"/>
            <stop offset="1" stopColor="var(--gradient-1-morning-end)"/>
        </radialGradient>
        <radialGradient 
            id="gradient-1-afternoon" 
            cx="0" 
            cy="0" 
            r="1" 
            gradientUnits="userSpaceOnUse" 
            gradientTransform="matrix(747.869 191.25 -191.25 747.869 2174.58 924)"
        >
            <stop offset="0" stopColor="var(--gradient-1-afternoon-start)"/>
            <stop offset="1" stopColor="var(--gradient-1-afternoon-end)"/>
        </radialGradient>
        <radialGradient 
            id="gradient-1-evening" 
            cx="0" 
            cy="0" 
            r="1" 
            gradientUnits="userSpaceOnUse" 
            gradientTransform="matrix(747.869 191.25 -191.25 747.869 3676.47 911.568)"
        >
            <stop offset="0" stopColor="var(--gradient-1-evening-start)"/>
            <stop offset="1" stopColor="var(--gradient-1-evening-end)"/>
        </radialGradient>
        <radialGradient 
            id="gradient-1-night" 
            cx="0" 
            cy="0" 
            r="1" 
            gradientUnits="userSpaceOnUse" 
            gradientTransform="matrix(747.869 191.25 -191.25 747.869 4199.16 818.94)"
        >
            <stop offset="0" stopColor="var(--gradient-1-night-start)"/>
            <stop offset="1" stopColor="var(--gradient-1-night-end)"/>
        </radialGradient>
      </defs>
    </svg>
  );
}

export default LayerTwoSvg;
