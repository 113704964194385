
import { useContext } from 'react';
import { BrowserView, MobileView } from 'react-device-detect';
import { GlobalScrollStateContext } from '../scroll-manager/scroll-manager';
import styles from './swipe-left.module.css';

export default function SwipeLeft() {
    
    const globalScrollState = useContext(GlobalScrollStateContext);
    return (
        <>
            <MobileView>
                <div className={styles.swipeLeft} style={{opacity: globalScrollState?.position !== 0 ? 0 : 1}}></div>
            </MobileView>
            <BrowserView>
                <div className={styles.scroll} style={{opacity: globalScrollState?.position !== 0 ? 0 : 1}}><div></div></div>
            </BrowserView>
        </>
    )
}