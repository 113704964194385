import React, { useContext, useEffect, useState } from 'react';
import { PropsWithChildren } from "react";
import { GlobalScrollStateContext, ScrollState } from '../scroll-manager/scroll-manager';
import styles from './background-manager.module.css';

export default function BackgroundManager (props: PropsWithChildren) {
    const [backgroundOpacities, setBackgroundOpacities] = useState<Array<number>>([1, 0, 0, 0]);
    const globalScrollState = useContext(GlobalScrollStateContext);

    useEffect(() => {
        if (globalScrollState) changeBackground(globalScrollState);
    }, [globalScrollState]);

    const getOpacity = (moment: number): React.CSSProperties => {
        return {
            opacity: backgroundOpacities[moment]
        }
    }

    const changeBackground = (scrollState: ScrollState) => {
        const newBackgroundOpacity = [0, 0, 0, 0];
        const opacityIndex = Math.floor((Math.abs(scrollState.position) + Math.abs(scrollState.delta * 4))/scrollState.viewSize);
        newBackgroundOpacity[opacityIndex] = 1;
        setBackgroundOpacities(newBackgroundOpacity);
    }

    return (
        <div id="background-wrapper" className={styles.backgroundWrapper}>
            <div className={`${styles.background} ${styles.morningBackground}`} style={getOpacity(0)}></div>
            <div className={`${styles.background} ${styles.afternoonBackground}`} style={getOpacity(1)}></div>
            <div className={`${styles.background} ${styles.eveningBackground}`} style={getOpacity(2)}></div>
            <div className={`${styles.background} ${styles.nightBackground}`} style={getOpacity(3)}></div>
            <div className={styles.childrenWrapper}>
                {props.children}
            </div>
        </div>
    );
}