import { useContext } from "react";
import { GlobalScrollStateContext, ScrollState } from "../../../scroll-manager/scroll-manager";
import PlaneSvg from "../elements/plane-svg/plane-svg";
import WorksList from "./works-list/works-list";
import styles from './works.module.css';

export default function Works() {
    
    const globalScrollState = useContext(GlobalScrollStateContext);

    const getPosition = () => {
        if (!globalScrollState) return;
        
        if (Math.abs(globalScrollState.position) >= globalScrollState.viewSize *2)
            return -300;

        return globalScrollState.position + (globalScrollState.viewSize * 2) - 300;
    }

    return (
        <div className={styles.planeWrapper} style={{left: getPosition()}}>
            <PlaneSvg />
            <WorksList />
        </div>
    )
}