import React from "react";
import styles from './abstract-content.module.css';

interface AbstractContentProps {
    active: boolean
}

export default function AbstractContent (props: React.PropsWithChildren<AbstractContentProps>) {

    return (
        <div className={styles.content} data-active={props.active}>
            {props.children}
        </div>
    )
}