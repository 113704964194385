import React, { MouseEventHandler, useState } from "react";
import AbstractContent from "../../abstract-content/abstract-content";
import VillageSvg from "../elements/village/village-svg";
import WindMillSvg from "../elements/windmill/windmill-svg";
import styles from './front-end.module.css';

interface FrontEndProps {
    onSelect: MouseEventHandler,
    active: boolean
}

export default function FrontEnd (props: FrontEndProps) {
    return (
        <>
            <AbstractContent active={props.active}>
                <h2>Front-end</h2>
                <p>Javascript and Typescript expert, I create web apps with any of javascript frameworks : React.js, Angular, Vue.js. 
I am also a CSS expert always up to date with W3C and I respect the new Web Content Accessibility Guidelines (WCAG / a11y) for a more accessible web.</p>
            </AbstractContent>
            <div className={styles.villageWrapper} data-active={props.active} onClick={props.onSelect}>
                <div className={styles.cta}><div></div></div>
                <VillageSvg />
            </div>
        </>
    );
}