import WorkDescription from '../work-description/work-description';
import styles from './works-list.module.css';
import worksDatas from '../../../../../datas/works.json';
import { satisfies } from 'semver';
import { useContext, useState } from 'react';
import SkillsList, { WorkSkill } from '../skills-list/skills-list';
import { GlobalScrollDispatchContext, GlobalScrollStateActionType, GlobalScrollStateContext } from '../../../../scroll-manager/scroll-manager';

interface WorkIface {
    id: number;
    company: string;
    description: string;
    realisation: string;
    skills: Array<WorkSkill>;
}


export default function WorksList () {
    const works: Array<WorkIface> = worksDatas satisfies Array<WorkIface>;
    const [selectedWork, setSelectedWork] = useState<WorkIface | null>(null);
    const globalScrollState = useContext(GlobalScrollStateContext);
    const scrollStateDispatch = useContext(GlobalScrollDispatchContext);

    const onWorkClick = (work:WorkIface) => {
        setSelectedWork(work);
        if (!scrollStateDispatch || !globalScrollState) return;

        scrollStateDispatch({type: GlobalScrollStateActionType.UPDATE, scrollState: {
            delta: 0,
            position: globalScrollState.viewSize * -2,
            viewSize: globalScrollState.viewSize,
            viewHeight: globalScrollState.viewHeight
        }});
    };

    return (
        <div className={styles.works}>
            <ul className={styles.worksList}>
            {works.map((work) => (
                <li 
                    key={work.id} 
                    data-active={selectedWork?.id === work.id}
                    onClick={() => onWorkClick(work)}
                >
                    <span>{work.company}</span>
                    <div className={styles.skills}>
                        <SkillsList skills={work.skills} />
                    </div>
                </li>
            ))}
            </ul>
            {(selectedWork !== null) ? <WorkDescription 
                description={selectedWork.description} 
                realisation={selectedWork.realisation} 
                skills={selectedWork.skills} />:<></>}
        </div>
    )
}