import React, { useContext, useEffect, useState } from "react";
import { GlobalScrollStateContext, ScrollState } from "../../scroll-manager/scroll-manager";
import LayerThreeSvg, { LayerThreeSvgColor } from "./layer-three-svg";
import styles from './layer-three.module.css';

export default function LayerThree() {
    const [position, setPosition] = useState<number>(0);
    const [color, setColor] = useState<LayerThreeSvgColor>(LayerThreeSvgColor.MORNING);
    const globalScrollState = useContext(GlobalScrollStateContext);

    const changeGradient = (scrollState: ScrollState) => {
        const gradientIndex = Math.floor((Math.abs(scrollState.position) + Math.abs(scrollState.delta * 4))/scrollState.viewSize);
        switch(gradientIndex) {
            case 0: setColor(LayerThreeSvgColor.MORNING); break;
            case 1: setColor(LayerThreeSvgColor.AFTERNOON); break;
            case 2: setColor(LayerThreeSvgColor.EVENING); break;
            case 3: setColor(LayerThreeSvgColor.NIGHT); break;
            default: break;
        }
        // setColor(gradientIndex);
    }

    useEffect(() => {
        if (!globalScrollState) return;
        setPosition(globalScrollState.position / 4);
        changeGradient(globalScrollState);
    }, [globalScrollState]);
    return (
        <div className={styles.layerThree} style={{
            left: `${position}px`
        }}>
            <LayerThreeSvg color={color} />
        </div>
    )
}