import { useContext, useState } from 'react';
import { GlobalScrollDispatchContext, GlobalScrollStateActionType, GlobalScrollStateContext } from '../../../scroll-manager/scroll-manager';
import styles from './contact.module.css';
import HouseSvg from './elements/house-svg/house-svg';

export default function Contact() {
    const [active, setActive] = useState<boolean>(false);

    const globalScrollState = useContext(GlobalScrollStateContext);
    const scrollStateDispatch = useContext(GlobalScrollDispatchContext);

    const toggleContact = () => {
        setActive(!active);
        if (!globalScrollState || !scrollStateDispatch) return;

        scrollStateDispatch({
            type: GlobalScrollStateActionType.UPDATE,
            scrollState: {
                delta: globalScrollState.delta,
                position: globalScrollState.viewSize * -3,
                viewSize: globalScrollState.viewSize,
                viewHeight: globalScrollState.viewHeight
            }
        });
    };

    return (
        <div className={styles.contactWrapper} data-active={active}>
            <a 
                href={`mailto:contact@quentinpavy.com`} 
                target="_blank" 
                className={styles.icon} 
                data-icon="mail" 
                data-index={1}
            />
            <a 
                href={`https://www.linkedin.com/in/quentin-pavy/`} 
                target="_blank" 
                className={styles.icon} 
                data-icon="linkedin" 
                data-index={2}
            />
            <a 
                href={`https://www.malt.fr/profile/quentinpavy`}
                target="_blank" 
                className={styles.icon} 
                data-icon="malt" 
                data-index={3}
            />
            <div className={styles.ctaWrapper}>
                <div className={styles.selected}></div>
                <div className={styles.cta}><div></div></div>
                <div className={styles.svgWrapper} onClick={() => toggleContact()}>
                    <HouseSvg />
                </div>
            </div>
        </div>
    )
}