import React from "react";

function PlaneSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fillRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit="2"
      clipRule="evenodd"
      viewBox="0 0 491 237"
    >
      <g transform="translate(-3619.11 -2921.61) translate(16.052 2563.72)">
        <g transform="matrix(-1 0 0 1 4169.74 220.907)">
          <circle
            cx="528.061"
            cy="226.122"
            r="10.127"
            fill="#333"
            transform="translate(-22.664 -8.97) scale(1.03797)"
          ></circle>
          <circle
            cx="528.061"
            cy="226.122"
            r="10.127"
            fill="#fff"
            transform="translate(111.947 48.672) scale(.78305)"
          ></circle>
          <path
            fill="#EAE4E6"
            d="M404 169l146-5c10 1 9 43 1 43-29.685.279-54.092-1.361-73-5l-96-17c-6.733-1.723-10.527-5.247-11.5-10.5-1.946-10.5 6.914-31.758 22.5-17.5l11 12z"
          ></path>
          <path
            fill="#40D8BC"
            d="M558.311 185l.689-34c.366-2.135 1.081-2.183 2-1l.967 35-.967 35c.131 1.829-1.884-.119-2-2-.01-.163-.689-33-.689-33z"
          ></path>
          <path
            fill="#333"
            d="M557.25 181H565c2.479 2.292 2 6 0 8h-7.75v-8z"
          ></path>
          <path fill="#4D4D4D" d="M478 207l19-61h4l-20 61h-3z"></path>
          <path
            fill="#4D4D4D"
            d="M493.209 156.874l1.901-7.544h4l-2.901 7.544h-3z"
            transform="translate(33.853 57.582)"
          ></path>
          <path
            fill="#4D4D4D"
            d="M478 207l19-61h4l-20 61h-3z"
            transform="translate(23 -2)"
          ></path>
          <path
            fill="#333"
            d="M463 147l77-2c6.197-3.771.748-7.927-2-8-12.5-.333-60.5 4.333-73 6-1.478.197-4.777 2.896-2 4z"
          ></path>
          <path
            fill="#333"
            d="M463 147l77-2c6.197-3.771.748-7.927-2-8-12.5-.333-60.5 4.333-73 6-1.478.197-4.777 2.896-2 4z"
            transform="matrix(.7646 .03033 -.0436 1.09897 109.167 37.223)"
          ></path>
          <path
            fill="#40D8BC"
            d="M463 147l77-2c6.197-3.771.748-7.927-2-8-12.5-.333-60.5 4.333-73 6-1.478.197-4.777 2.896-2 4z"
            transform="matrix(.31683 .02808 -.08052 .9085 377.169 73.893)"
          ></path>
        </g>
        <path
          fill="#3B585A"
          d="M5550.86 2907.61H5648.750999999999V2911.5570000000002H5550.86z"
          transform="translate(-6 11) matrix(2.55387 0 0 .76003 -10372.3 -1826.26)"
        ></path>
        <path
          fill="#3B585A"
          d="M5550.86 2907.61H5648.750999999999V2911.5570000000002H5550.86z"
          transform="translate(-6 11) matrix(.407 -.3086 .45921 .60562 457.554 336.681)"
        ></path>
        <path
          fill="#3B585A"
          d="M5550.86 2907.61H5648.750999999999V2911.5570000000002H5550.86z"
          transform="translate(-6 11) matrix(.45463 1.99187 -.74098 .16912 3685.91 -11161)"
        ></path>
      </g>
    </svg>
  );
}

export default PlaneSvg;
