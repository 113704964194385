import { ScrollState } from '../../scroll-manager/scroll-manager';
import AbstractSection from '../abstract-section/abstract-section';
import styles from './evening-content.module.css';
import Works from './works/works';

export default function EveningContent() {

    return (
        <AbstractSection>
            <div className={styles.wrapper}>
                <h2 className={styles.h2}>Works</h2>
            </div>
            <Works />
        </AbstractSection>
    )
}