import React, { useEffect } from "react";
import styles from './layer-three.module.css';

export enum LayerThreeSvgColor {
    MORNING = 'var(--layer-3-morning)',
    AFTERNOON = 'var(--layer-3-afternoon)',
    EVENING = 'var(--layer-3-evening)',
    NIGHT = 'var(--layer-3-night)'
}

interface LayerThreeSvgProps {
    color: LayerThreeSvgColor
}

function LayerThreeSvg(props: LayerThreeSvgProps) {

    return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fillRule="evenodd"
          strokeLinejoin="round"
          strokeMiterlimit="2"
          clipRule="evenodd"
          viewBox="0 0 7680 799"
        >
          <path
            className={styles.path}
            fill={props.color}
            fillOpacity="0.3"
            fillRule="nonzero"
            d="M611.728 298.626l-250.632 244.2h-22.278l-114.177 101.69-91.899-54.426-214.259 288.267-533.373 4.384V928.5h7680v-35.091l-5442.72-5.406-284.76-440.422-48.27 75.193L985.82 0 655.357 363.077l-43.629-64.451z"
            transform="translate(0 -151.5) translate(614.89 151.5)"
          ></path>
        </svg>
    );
}

export default LayerThreeSvg;