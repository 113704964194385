import { useState } from 'react';
import styles from './skills-list.module.css';

export interface WorkSkill {
    name: string;
    slug: string;
}

interface SkillsListProps {
    skills: Array<WorkSkill>,
    expandable?: boolean
}

export default function SkillsList(props: SkillsListProps) {
    const [selectedSkill, setSelectedSkill] = useState<WorkSkill>();
    return (
        <div className={styles.skills}>
            { props.skills.map((skill, index) => (
                <div key={index} className={styles.skillWrapper} data-expandable={(props.expandable)? true: false} data-expanded={(selectedSkill?.slug === skill.slug)} onClick={() => props.expandable ? setSelectedSkill(skill): null}>
                    <span className={styles.icon} data-icon={skill.slug}> {(skill.slug === 'vuejs') ? <><span className={styles.path1}></span><span className={styles.path2}></span></>: <></>}</span>
                    {(props.expandable && selectedSkill?.slug === skill.slug) ? <span>{skill.name}</span> : <></>}
                </div>
            ))}
        </div>
    )
}