import { useContext, useEffect, useState } from 'react';
import AfternoonContent from '../content-components/afternoon-content/afternoon-content';
import EveningContent from '../content-components/evening-content/evening-content';
import MorningContent from '../content-components/morning-content/morning-content';
import NightContent from '../content-components/night-content/night-content';
import { GlobalScrollStateContext, ScrollState } from '../scroll-manager/scroll-manager';
import styles from './content-wrapper.module.css';


export default function ContentWrapper () {
    const globalScrollState = useContext(GlobalScrollStateContext);

    return (
        <div className={styles.contentWrapper} style={{transform: `translateX(${globalScrollState?.position}px)`}}>
            <MorningContent />
            <AfternoonContent />
            <EveningContent />
            <NightContent />
        </div>
    )
}