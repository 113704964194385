import React, { useContext, useEffect, useState } from "react";
import styles from './sun.module.css';
import { GlobalScrollStateContext, ScrollState } from "../../scroll-manager/scroll-manager";

interface SunProperties {
    x: number;
    y: number;
}

export default function Sun () {

    const [sunPosition, setSunPosition] = useState<SunProperties>({x: 45, y: 330});
    const [sunSize, setSunSize] = useState<number>(103);
    const globalScrollState = useContext(GlobalScrollStateContext);

    // Calculate parabolic line chatgbt answer 
    const calculateY = (x: number, scrollState: ScrollState):number => {
        const xa = 45;
        const ya = 330;
        const xb = scrollState.viewSize / 2;
        const yb = (scrollState.viewHeight || 920) - 98;
        const xc = scrollState.viewSize - 90;
        const yc = 330;

        const a = ((yb - yc) * (xa - xb) - (ya - yb) * (xb - xc)) /
          ((xa - xb) * (xb - xc) * (xc - xa));
        const b = ((ya - yb) / (xa - xb)) - a * (xa + xb);
        const c = ya - a * xa ** 2 - b * xa;

        return a * Math.pow(x, 2) + b*x + c;
    }

    // const quadraticCalculation = (x: number, a: number, b: number, c: number):number => {
    //     console.log(Math.pow((a*x), 2) + (b*x) + c);
    //     return a* Math.pow(x, 2) + (b*x) + c;
    // }

    
    useEffect(() => {
        const changePosition = (scrollState: ScrollState) => {
            if (scrollState.position !== 0) {
                let newX = Math.abs(scrollState.position / 2) - 150;
                if (newX < 45) newX = 45;
                const pos = {
                    x: newX,
                    // y: quadraticCalculation(newX, -167/278400, 167/145, 1037085/3712),
                    y: calculateY(Math.abs(scrollState.position / 2), scrollState),
                } ;
                setSunPosition(pos);
                return pos;
            }
    
            return sunPosition;
        }
    
        // Calculate size from y position - chatgbt answer
        const changeSize = (y: number,scrollState: ScrollState) => {
            const ya = 330;
            const sa = 103;
            const yb = (scrollState.viewHeight || 920) - 98;
            const sb = 50;
    
            const fpy = (sb - sa)/(yb - ya);
            const b = sa - fpy * ya;
    
            setSunSize(fpy * y + b);
        }

        if (!globalScrollState) return;
        
        const pos = changePosition(globalScrollState);

        if (pos.y) {
            changeSize(pos.y, globalScrollState);
        }
    }, [globalScrollState]);

    return (
        <div className={styles.sun} style={{
            bottom: `${sunPosition.y}px`,
            left: `${sunPosition.x}px`,
            width: `${sunSize}px`,
            height: `${sunSize}px`,
        }} />
    )
}