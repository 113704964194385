import React, { useState } from 'react';
import './App.css';
import LayerOne from './components/background-elements/layer-one/layer-one';
import LayerThree from './components/background-elements/layer-three/layer-three';
import LayerTwo from './components/background-elements/layer-two/layer-two';
import Moon from './components/background-elements/moon/moon';
import Sun from './components/background-elements/sun/sun';
import BackgroundManager from './components/background-manager/background-manager';
import ContentWrapper from './components/content-wrapper/content-wrapper';
import ScrollManager from './components/scroll-manager/scroll-manager';
import SwipeLeft from './components/swipe-left/swipe-left';
import Helmet from 'react-helmet';


function App() {
  return (
    <div className="App">
      <Helmet>
          <meta charSet="utf-8" />
          <title>Quentin Pavy - Fullstack expert Front-end / Back-end</title>
      </Helmet>
      <ScrollManager>
        <BackgroundManager>
          <SwipeLeft />
          <ContentWrapper />
          <LayerThree />
          <LayerTwo />
          <LayerOne />
          <Sun />
          <Moon />
        </BackgroundManager>
      </ScrollManager>
    </div>
  );
}

export default App;
