import React, { useContext, useEffect, useState } from "react";
import { GlobalScrollStateContext, ScrollState } from "../../scroll-manager/scroll-manager";
import LayerOneSvg, { LayerOneSvgGradient } from "./layer-one-svg";
import styles from './layer-one.module.css';

export default function LayerOne() {
    const [position, setPosition] = useState<number>(0);
    const [gradient, setGradient] = useState<LayerOneSvgGradient>(LayerOneSvgGradient.MORNING);
    const globalScrollState = useContext(GlobalScrollStateContext);
    
    const changeGradient = (scrollState: ScrollState) => {
        const gradientIndex = Math.floor((Math.abs(scrollState.position) + Math.abs(scrollState.delta * 4))/scrollState.viewSize);
        setGradient(gradientIndex);
    }

    useEffect(() => {
        if(!globalScrollState) return;
        setPosition(globalScrollState.position);
        changeGradient(globalScrollState);
    }, [globalScrollState]);

    return (
        <div className={styles.layerOne} style={{
            left: `${position}px`
        }}>
            <LayerOneSvg gradient={gradient} />
        </div>
    )
}